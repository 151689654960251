import { createApi } from ".";
// Assuming createApi function is imported from another file

export const getCoupon = async (data: {
  couponId: string;
}) => {
  try {
    const res = await createApi().get<{ isValid: boolean }>(
      "/coupon",
      data
    );
    return res.data?.isValid; // Return the isValid value
  } catch (error) {
    return {
      error: error.message || error
    };
  }
};
