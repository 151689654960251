import { STT_OK } from "src/const/app.const";
import { STT_NOT_FOUND, STT_EMPTY } from "../const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';
import { Opportunity, Transaction } from "src/types/models";
interface ICreateDraft {
    business_id: string;
    category: string;
    type: string;
  }
  
  interface IInitializeDraft {
    location: string;
    value: number;
    discount: number;
    quantity: number;
    max_per_person: number;
    expiration_date: string;
    opportunity_id: string;
  }
export const getDraftById = async (
    id: string,
  ) => {
    const res = await createApi().get<{ result: Opportunity; message?: string }>(
       "draft/id",
      { id }
    );
    if (res?.status === STT_NOT_FOUND) {
      toast.error("Sorry, we couldn't find this draft.");
      return undefined;
    }
  
    if (res?.status !== STT_OK) {
      toast.error(res.data?.message || "Something went wrong.");
      return undefined;
    }
  
    return res.data?.result;
  };

  export const createDraft = async (data: ICreateDraft) => {
    const res = await createApi().post("draft/create-deal", data);
    if (res.status === 400) {
      return toast.error("Sorry, something went wrong.");
    }
    return res;
  };

  export const deleteDraft = async (data : {id:string}) => {
    const res = await createApi().delete<{ message: string; ok: true }>(`/drafts`,{}, {data});
    if (res.status === STT_OK) {
      return res.data;
    } else {
      toast.error('Sorry, something went wrong.');
      return null;
    }
  };
  
  
  export const initializeDraft = async (data: IInitializeDraft) => {
    const res = await createApi().put<{ result: Opportunity; message?: string }>(
      `draft/create-deal`,
      data,
    );
    if (res.status === 400) {
      return toast.error("Sorry, something went wrong.");
    } else if (res?.status !== STT_OK) {
      toast.error(res.data?.message || "Something went wrong.");
      return undefined;
    }
    return res;
  };

  export const getDrafts = async (data: { id: string; limit?: number; offset?: number }) => {
    const res = await createApi().get<{
      results: Transaction[];
      total: number;
      message?: string;
    }>("/drafts", data);
    if (res.status === STT_EMPTY) {
      return { results: [], total: 0 };
    }
    if (res.status !== STT_OK || !res.data) {
      toast.error(`Error : ${res.data?.message || res.originalError}`);
      return { results: [], total: 0 };
    }
    return { results: res.data.results, total: res.data.total };
  };