/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { TOKEN } from "./config";

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL =
  process.env.NEXT_PUBLIC_API_ENDPOINT || "http://127.0.0.1:3001/api";
const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: any) => {
    const token =
      window.localStorage.getItem(TOKEN) ||
      window.sessionStorage.getItem(TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = (response: any) => response;
  const onResponseError = (err: any) => {
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
