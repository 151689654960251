import { STT_OK, STT_UNAUTHORIZED } from "src/const/app.const";
import { STT_NOT_FOUND, STT_EMPTY } from "../const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';
import { Opportunity, OpportunityPreview, Transaction } from "src/types/models";
import { FeedType } from "src/components/contexts/opportunityContext";

interface ICreateOpportunity {
  business_id: string;
  category: string;
  type: string;
}

interface IInitializeOpportunity {
  location: string;
  value: number;
  discount: number;
  quantity: number;
  max_per_person: number;
  expiration_date: string;
  opportunity_id: string;
}

interface IUpdateOpportunity {
  quantity?: number;
  expiration_date?: string;
  opportunity_id: string;
  active?: number;
}

interface IgetOpportunity {
  business_id: string;
}

export const createOpportunity = async (data: ICreateOpportunity) => {
  const res = await createApi().post("opportunities/create-deal", data);
  if (res.status === 400) {
    return toast.error("Sorry, something went wrong.");
  }
  return res;
};

export const initializeOpportunity = async (data: IInitializeOpportunity) => {
  const res = await createApi().put<{ result: Opportunity; message?: string }>(
    `opportunities/create-deal`,
    data,
  );
  if (res.status === 400) {
    return toast.error("Sorry, something went wrong.");
  } else if (res?.status !== STT_OK) {
    toast.error(res.data?.message || "Something went wrong.");
    return undefined;
  }
  return res;
};

export const updateOpportunity = async (data: Partial<IUpdateOpportunity>) => {
  const res = await createApi().put<{
    message?: string;
    status: number;
  }>(`opportunities/id`, data);
  if (res?.status !== STT_OK || !res.data) {
    toast.error(res.data?.message || "Something went wrong.");
    return undefined;
  }

  return true;
};

export const increaseViewCount = async (id: string) => {
  const res = await createApi().put<{
    views: number;
    message?: string;
  }>(`opportunities/${id}/views`, {});
  if (res?.status !== STT_OK || !res.data) {
    // toast.error(res.data?.message || "Something went wrong.");
    return 0;
  }

  return res.data.views;
};

export const getOpportunities = async (data: IgetOpportunity) =>
  createApi().get("opportunities/create-deal", data);

export const getMyOpportunities = async (data: { id: string; limit?: number; offset?: number }) => {
  const res = await createApi().get<{
    results: Transaction[];
    total: number;
    message?: string;
  }>("/opportunities/business", data);
  if (res.status === STT_EMPTY) {
    return { results: [], total: 0 };
  }
  if (res.status !== STT_OK || !res.data) {
    // toast.error(`Error : ${res.data?.message || res.originalError}`);
    return { results: [], total: 0 };
  }
  return { results: res.data.results, total: res.data.total };
};

export const getOpportunitiesById = async (
  id: string,
  isBusiness?: boolean,
  eventDate?: string,
) => {
  const res = await createApi().get<{ result: Opportunity; message?: string }>(
    isBusiness ? "opportunities/business/id" : "opportunities/id",
    { id, eventDate }
  );
  
  if (res?.status === STT_NOT_FOUND) {
    toast.error("Sorry, we couldn't find this opportunity.");
    return undefined;
  }

  if (res?.status !== STT_OK) {
    // toast.error(res.data?.message || "Something went wrong.");
    return undefined;
  }

  return res.data?.result;
};

export const getEventSlotsById = async (
  id: string,
) => {
  const res = await createApi().get<{ results: any; message?: string }>(
    `opportunity-slots/${id}`
  );
  if (res?.status === STT_NOT_FOUND) {
    toast.error("Sorry, we couldn't find this opportunity.");
    return undefined;
  }

  if (res?.status !== STT_OK) {
    // toast.error(res.data?.message || "Something went wrong.");
    return undefined;
  }

  return res.data?.results;
};

export const getOpportunitiesFilter = async (
  filter: FeedType,
  data: Partial<{
    content: string;
    offset: number;
    limit: number;
    category: string;
    type: string;
    latitude: number;
    longitude: number;
    minprice: number;
    maxprice: number;
  }>,
) => {
  try {
    await createApi();
    const response = await createApi().get<{
      total: number;
      opportunities: OpportunityPreview[];
      message?: string;
    }>(`/opportunities/home/${filter}`, data);

    if (response.status === null || response.problem === "NETWORK_ERROR") {
      return;
    }

    if (response.status === STT_EMPTY) {
      return { opportunities: [], total: 0 };
    }

    // Expire token scenario. The page will be reloaded. Don't show the error
    if (response.status === STT_UNAUTHORIZED) {
      return { opportunities: [], total: 0 };
    }
    if (response.status !== STT_OK || !response.data) {
      // toast.error(`${response.data?.message || response.originalError}`);
      return { opportunities: [], total: 0 };
    }
    return {
      opportunities: response.data.opportunities,
      total: response.data.total,
    };
  } catch (error) {
    console.log(error);
    return { opportunities: [], total: 0 };
  }
};

export const deleteOpportunity = async (id: string) => {
  const res = await createApi().delete<{ message: string; ok: true }>(`/opportunities/${id}`);

  if (res.status !== STT_OK) {
    return undefined;
  }
  return true;
};

export const updateEventSlots = async (events: {
  id: string;
  opportunityId: string;
  totalTickets: number;
  price: string;
}[], opportunityId: string) => {
    const res = await createApi().put<{ result: any; message?: string }>(
      `opportunity-slots/${opportunityId}`, { events }
    );
  
    if (res?.status !== STT_OK) {
      toast.error(res.data?.message || "Something went wrong.");
      return undefined;
    }

    return res.data?.result;
  };

  export const searchOpportunities = async (data: { 
    searchText: string, 
    minPrice?: number; 
    maxPrice?: number; 
    latitude?: number; 
    longitude?: number 
  }) => {
    const res = await createApi().get<{
      results: {suggestion: string}[];
      message?: string;
    }>("/opportunities/search", data);
    if (res.status === STT_EMPTY) {
      return [];
    }
    if (res.status !== STT_OK || !res.data) {
      // toast.error(`Error : ${res.data?.message || res.originalError}`);
      return [];
    }
    return res.data.results;
  };

  export const getRelatedOpportunities = async (id: string, userId:string,limit: number, offset: number) => {
    try {
      const response = await createApi().get<{ result: any }>(
        `/opportunities/recommendation?id=${id}&userId=${userId}&limit=${limit}&offset=${offset}`
      );        
      if (response.status !== STT_OK || !response.data) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.data.result;
    } catch (error) {
      console.error("Error fetching related opportunities:", error);
      throw error;
    }
  };