/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment";
import router from "next/router";
import React, { useState, createContext, useEffect, useContext } from "react";
import { AccountService } from "src/services";

export const STORAGE_TOKEN = "token";
export const STORAGE_EXPIRATION_TIME = "token-expiration-time";
export const STORAGE_GENERATION_TIME = "token-generation-time";
export const STORAGE_BUSINESS = "business"; // Selected business
export const STORAGE_ACCOUNT = "account";

// Common for User and Business
export type AccountInfoType = {
  [x: string]: any;
  id: string;
  name: string;
  email: string;
  username: string;
  password?: string;
  number: string;
  image?: string;
  description?: string;
  website?: string;
  referralCode?: string;
  community: {
    community_is_active: boolean;
    community_zipCode: string;
    country: string;
  };
  lastTimeCheckCommunity: Date;
};

export type AccountContextType = {
  // TODO: Check to refactor
  isShowLogin: boolean;
  setShowLogin: (value: boolean) => void;

  logout: () => void;
  login: (data: AccountInfoType & { accessToken: string; expiration: Date }) => void;
  selectBusiness: (info: AccountInfoType | false) => void;
  updateUserInfo: (info: AccountInfoType) => void;

  isLogged: boolean;
  isBusiness: boolean;
  accountInfo: AccountInfoType | false; // Selected Profile
  userInfo: AccountInfoType | false; // User Log info

  checkHasNewFeed: boolean; 
  setCheckHasNewFeed: (value: boolean) => void;
};

// Create a context object
export const AccountContext = createContext<AccountContextType>({
  isShowLogin: false,
  setShowLogin: () => { },

  logout: () => { },
  login: () => { },
  selectBusiness: () => 0,
  updateUserInfo: () => 0,

  isLogged: false,
  isBusiness: false,
  accountInfo: false, // Selected Account
  userInfo: false,

  checkHasNewFeed: false,
  setCheckHasNewFeed: () => { },
});

export const useAccountContext = () => useContext(AccountContext);

// Create a provider for the context object
export const AccountProvider = ({ children }: any) => {
  // Set the initial value for the context object
  const [isReady, setIsReady] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<AccountInfoType | false>(false);
  const [businessInfo, setBusinessInfo] = useState<Partial<AccountInfoType> | false>(false);
  const [isShowLogin, setShowLogin] = useState(false);
  const [checkHasNewFeed, setCheckHasNewFeed] = useState<boolean>(false);

  /** Refresh Token */
  useEffect(() => {
    if (!userInfo) {
      return;
    }

    const tokenGenerated = localStorage.getItem(STORAGE_GENERATION_TIME);
    if (!tokenGenerated) {
      return;
    }

    // If is 1 day since we generated the token
    if (moment().isBefore(moment(+tokenGenerated).add(1, "day"))) {
      return;
    }

    AccountService.refreshAuth().then((response) => {
      if (!response) {
        return logout();
      }
      const { accessToken, expiration } = response;
      localStorage.setItem(STORAGE_TOKEN, accessToken);
      localStorage.setItem(
        STORAGE_EXPIRATION_TIME,
        moment(expiration).toDate().getTime().toString(),
      );
      localStorage.setItem(STORAGE_GENERATION_TIME, moment().toDate().getTime().toString());
    });
  }, [userInfo]);
  // Load the information from the localstorage
  useEffect(() => {
    // Check Token expiration time
    const tokenExpirationDate = localStorage.getItem(STORAGE_EXPIRATION_TIME);
    if (tokenExpirationDate && +tokenExpirationDate < Date.now()) {
      logout();
      setIsReady(true);
      return;
    }
    // If the token Expiration Date is within 2 days, we will renovate the token here async

    const userInfoData = JSON.parse(localStorage.getItem(STORAGE_ACCOUNT) || "null");
    userInfoData && setUserInfo(userInfoData);

    const businessInfoData = JSON.parse(localStorage.getItem(STORAGE_BUSINESS) || "null");
    businessInfoData && setBusinessInfo(businessInfoData);

    setIsReady(true);
  }, []);

  const logout = () => {
      localStorage.removeItem(STORAGE_ACCOUNT);
      localStorage.removeItem(STORAGE_TOKEN);
      localStorage.removeItem(STORAGE_BUSINESS);
      localStorage.removeItem(STORAGE_EXPIRATION_TIME);
    setUserInfo(false);
    setBusinessInfo(false);
    router.replace("/");
  };
  
  const login = (data: AccountInfoType & { accessToken: string; expiration: Date }) => {
      const { accessToken, expiration, ...userInfo } = data;
      localStorage.setItem(STORAGE_TOKEN, accessToken);
      localStorage.setItem(STORAGE_EXPIRATION_TIME, moment(expiration).toDate().getTime().toString());
      localStorage.setItem(STORAGE_GENERATION_TIME, moment().toDate().getTime().toString());
      localStorage.setItem(STORAGE_ACCOUNT, JSON.stringify(userInfo));
    setUserInfo(userInfo);
  };
  
  const updateUserInfo = (userInfo: AccountInfoType) => {
      localStorage.setItem(STORAGE_ACCOUNT, JSON.stringify(userInfo));
    setUserInfo(userInfo);
  };
  
  const selectBusiness = (data: AccountInfoType | false) => {
      if (data === false) {
        localStorage.removeItem(STORAGE_BUSINESS);
      } else {
        localStorage.setItem(STORAGE_BUSINESS, JSON.stringify(data));
      }
    setBusinessInfo(data || false);
  };

  // We wait for isReady. So we are user is logged or not
  const isLogged = !isReady || (userInfo && !!userInfo.id);
  const isBusiness = businessInfo && !!businessInfo.id;
  const getAccountInfo = () => {
    // if (!isReady) {
    //   return;
    // }
    if (!isLogged) {
      return false;
    }
    return (isBusiness ? businessInfo : userInfo) as AccountInfoType;
  };
  
  const accountInfo = getAccountInfo();
  
  return (
    <AccountContext.Provider
      value={{
        isShowLogin,
        setShowLogin,
        logout,
        login,
        isLogged,
        isBusiness,
        userInfo,
        accountInfo,
        selectBusiness,
        updateUserInfo,
        businessInfo,
        checkHasNewFeed,
        setCheckHasNewFeed,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
