/* eslint-disable @next/next/no-html-link-for-pages */
import React, { useContext, useState, useEffect } from "react";
import { SubmitButton } from "../common";
import { EMAIL_REGEX } from "src/const/app.const";
import { AccountService } from "src/services";
import { FaSpinner } from "react-icons/fa";
import {
  AccountContext,
  AccountContextType,
} from "../contexts/ToggleAccountContext";
import NewModal from "../common/Modal-Redesign";
import NewCommonInput from "../common/CommonInput-Redesign";
import Link from "next/link";
import Image from "next/image";

interface LoginModalProps {
  open: boolean;
  onClose: () => void;
  onSignUp: (flag: boolean) => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ open, onClose, onSignUp }) => {
  const { login } = useContext<AccountContextType>(AccountContext);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>("");

  const handleLogin = async () => {
    setLoading(true);
    const data = await AccountService.login({ email, password });
    if (!data) {
      setLoading(false);
      return;
    }
    login(data);
    setLoading(false);
    onClose();
  };
  useEffect(() => {
    const body = document.body;
    if (open) {
      body.style.overflow = "hidden"; // disable scrolling on the body element
    } else {
      body.style.overflow = "auto"; // enable scrolling on the body element
    }
  }, [open]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLogin();
  };
  return (
    <>
      {open ? (
        <>
          <NewModal
            onClose={onClose}
            open={open}
            closeButton={false}
            className="max-w-[800px] px-0 py-0 sm:min-w-[650px]"
          >
            <div className="flex min-h-[100vh] w-full justify-center md:min-h-[460px]">
              <div className="hidden h-full w-[45%] rounded-l-lg sm:block">
                <div className="absolute left-4 top-5 z-10 max-w-[45%] text-3xl font-semibold text-white">
                  Welcome back to <br />
                  <span className="brand-text-color font-bold">MindKind</span>
                </div>
                <div className="relative h-full w-full rounded-l-lg">
                  <img
                    src="/coffee-shop-login-min.jpg"
                    alt="Community Image"
                    className="z-0 min-h-[100vh] w-full rounded-l-lg object-cover md:min-h-[460px]"
                    loading="lazy"
                  />
                </div>
                <div className="absolute top-0 left-0 h-full w-[45%]">
                  <Image
                    src="/NewLogo.svg"
                    alt="MindKind Logo"
                    width={40}
                    height={40}
                    className="absolute right-2 bottom-2 z-10"
                  />
                </div>
                <div className="z-2 absolute top-0 left-0 h-full w-[45%] rounded-l-lg bg-black opacity-40" />
              </div>
              <div className="flex w-full flex-col items-center p-8 sm:w-[55%]">
                <div
                  className="close-button absolute right-3 top-6 z-10 -translate-y-[13px] cursor-pointer rounded-full p-2"
                  onClick={(e) => {
                    onClose();
                  }}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 28 28"
                    fill="none"
                    className="flex flex-shrink-0 items-center"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.6004 1.42043C25.8204 0.64043 24.5604 0.64043 23.7804 1.42043L14.0004 11.1804L4.22043 1.40043C3.44043 0.62043 2.18043 0.62043 1.40043 1.40043C0.62043 2.18043 0.62043 3.44043 1.40043 4.22043L11.1804 14.0004L1.40043 23.7804C0.62043 24.5604 0.62043 25.8204 1.40043 26.6004C2.18043 27.3804 3.44043 27.3804 4.22043 26.6004L14.0004 16.8204L23.7804 26.6004C24.5604 27.3804 25.8204 27.3804 26.6004 26.6004C27.3804 25.8204 27.3804 24.5604 26.6004 23.7804L16.8204 14.0004L26.6004 4.22043C27.3604 3.46043 27.3604 2.18043 26.6004 1.42043Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="w-full max-w-[400px]">
                  <form onSubmit={handleSubmit}>
                    <div className="flex w-full flex-col gap-2 rounded-[5px]">
                      <h1 className="mb-2 text-2xl font-medium">Log in</h1>
                      <NewCommonInput
                        value={email}
                        name="email"
                        label="Email"
                        onChangeValue={setEmail}
                        className="mt-0 rounded rounded-t-md shadow-none"
                      />
                      <NewCommonInput
                        password
                        value={password}
                        name="password"
                        label="Password"
                        onChangeValue={setPassword}
                        className="shadow-none"
                      />
                    </div>
                    <div className="pt-3 pl-1 underline">
                      <Link
                        href="/auth/forgotpass"
                        className="cursor-pointer text-sm"
                        onClick={() => onClose()}
                      >
                        Forgot password?
                      </Link>
                    </div>
                    {loading ? (
                      <div className="brand-color mt-4 w-full rounded-md py-2">
                        <p className="text-center text-lg text-white">
                          <FaSpinner className="inline-block w-full animate-spin " />
                        </p>
                      </div>
                    ) : (
                      <SubmitButton
                        disabled={
                          !Boolean(
                            email &&
                              Boolean(email.match(EMAIL_REGEX)) &&
                              password
                          )
                        }
                        label="Log in"
                        onClick={handleLogin}
                      />
                    )}
                  </form>
                </div>
                <div className="flex w-full max-w-[400px] items-center justify-center py-5">
                  <div className="h-[1px] w-[30%] bg-black" />
                  <p className="px-2 font-semibold">or</p>
                  <div className="h-[1px] w-[30%] bg-black" />
                </div>
                <button
                  onClick={() => {
                    onSignUp(true);
                    onClose();
                  }}
                  className="mb-2 w-full max-w-[400px] rounded-md border border-[#EAAC00] py-2 px-2 font-bold text-[#eaac00]"
                >
                  Sign up
                </button>
              </div>
            </div>
          </NewModal>
        </>
      ) : null}
    </>
  );
};

export default LoginModal;
