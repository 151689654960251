import React from "react";
import { twMerge } from "tailwind-merge";

interface FilterPopupProps {
  align: string;
  children: React.ReactNode;
  width?: string;
}

const FilterPopup: React.FC<FilterPopupProps> = ({
  align,
  children,
  width,
}) => {
  return (
    <div
      className={twMerge(
        width,
        "absolute top-20 left-0 rounded-2xl bg-white shadow md:top-16",
        `${
          align === "center" && "md:left-1/2 md:-translate-x-1/2 md:transform"
        }`,
        `${align === "right" && "md:right-0 md:left-auto"}`,
        `${align === "left" && "md:left-0 md:right-auto"}`
      )}
    >
      {children}
    </div>
  );
};

export default FilterPopup;
