import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MdPersonOutline, MdOutlineStoreMallDirectory } from "react-icons/md";
import Image from "next/image";
import Link from "next/link";
import NavLoggedInMenu from "./NavLoggedInMenu";
import MobileNav from "../nav/MobileNav";
import type { AccountContextType } from "src/components/contexts/ToggleAccountContext";
import { AccountContext } from "src/components/contexts/ToggleAccountContext";
import { COMMON_X_MARGIN } from "src/const/app.const";
import { useRouter } from "next/router";
import { DealLocationFilter, DealPriceFilter } from "./Filters";
import { Skeleton } from "@mui/material";
import SearchBar from "./Filters/SearchBar";
import WalletPopup from "./Filters/WalletPopup";
import Community from "../nav/community";
import { useFilterContext } from "../contexts/FilterContext/FilterContext";
import {
  FeedType,
  OpportunityContext,
  OpportunityContextType,
} from "../contexts/opportunityContext";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleNav({
  unreadMessages,
  setShowSignUp,
  pageTitle,
}: {
  unreadMessages: number;
  setShowSignUp: (value: boolean) => void;
  pageTitle: string;
}) {
  const { isLogged, isBusiness, setShowLogin, accountInfo, checkHasNewFeed } =
    useContext<AccountContextType>(AccountContext);
  const router = useRouter();
  const [showBannerAndFilters, setShowBannerAndFilters] = useState(false);
  const { filterDispatch } = useFilterContext();

  const { setFeed, setCurrentOption } = useContext<OpportunityContextType>(OpportunityContext);

  useEffect(() => {
    if (router.route === "/") {
      setShowBannerAndFilters(true);
      return;
    }
    setShowBannerAndFilters(false);
  }, [router.route]);

  const [isLoading, setIsLoading] = useState(true);

  const handleLogoClick = () => {
    setCurrentOption(0);
    setFeed(FeedType.NEW);
    filterDispatch({ type: "SET_CONTENT", payload: "" });
  };
  return (
    <>
      <div className="md:hidden">
        <MobileNav title={pageTitle} />
      </div>
      <Disclosure as="nav" className="navbar hidden border border-b bg-gray-50 md:block">
        <div className={`${COMMON_X_MARGIN} mt-2 min-h-[73px] max-w-7xl`}>
          <div className="relative flex h-16 items-center justify-between gap-x-2">
            <div className="relative flex min-h-[100%] flex-none items-center justify-center sm:items-stretch sm:justify-start">
              <Link
                href={isBusiness ? `/merchant/${accountInfo?.id}` : "/"}
                onClick={handleLogoClick}
                className={`z-10 ml-[16px] flex flex-shrink-0 items-center ${
                  isLoading ? "opacity-0" : "opacity-100"
                } `}
              >
                <Image
                  src="/NewLogo.svg"
                  alt="MindKind"
                  width={40}
                  height={40}
                  className="mr-2 -ml-4 h-12 w-auto lg:block"
                  onLoadingComplete={() => {
                    setIsLoading(false);
                  }}
                />
                <span className="text-[19px] font-semibold text-brand">MindKind</span>
              </Link>
              <div
                className={`ml-[-125px]  flex items-center ${
                  isLoading ? "opacity-100" : "opacity-0"
                }`}
              >
                <Skeleton variant="rectangular" animation="wave" width={55} height={55}></Skeleton>
                <Skeleton
                  variant="text"
                  className="ml-2 "
                  animation="wave"
                  width={65}
                  height={30}
                ></Skeleton>
              </div>
            </div>
            {showBannerAndFilters && (
              <div className="flex grow justify-center">
                {isLoading ? (
                  <>
                    <Skeleton variant="rounded" animation="wave" width={290} height={35}></Skeleton>
                  </>
                ) : (
                  <>
                    <SearchBar />
                    <div className="ml-2 flex  items-center justify-evenly sm:ml-5">
                      <DealLocationFilter />
                      <DealPriceFilter />
                      <WalletPopup />
                      <Community />
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="inset-y-0 right-0 flex flex-none items-center sm:static sm:inset-auto sm:ml-6 ">
              <Menu as="div" className="relative ml-3">
                <div>
                  {isLoading ? (
                    <Skeleton variant="circular" animation="wave" height={50} width={50}></Skeleton>
                  ) : (
                    <Menu.Button className="flex rounded-full text-sm focus:outline-none ">
                      <span className="sr-only">Open user menu</span>
                      {!isBusiness ? (
                        <MdPersonOutline className="h-8 w-8 " aria-hidden="true" />
                      ) : (
                        <MdOutlineStoreMallDirectory
                          className="h-8 w-8 rounded-full"
                          aria-hidden="true"
                        />
                      )}
                      {(unreadMessages > 0 || checkHasNewFeed) && (
                        <div className="brand-color-semi-transparent absolute -top-1 -right-1 inline-flex h-3 w-3 rounded-full">
                          {/* {unreadMessages} */}
                        </div>
                      )}
                    </Menu.Button>
                  )}
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-6 w-48 origin-top-right rounded-md border-2 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {isLogged ? (
                      <NavLoggedInMenu unreadMessages={unreadMessages} />
                    ) : (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "hover:brand-color-opaque" : "",
                                "block w-full px-4 py-4 text-center text-sm font-semibold text-black",
                              )}
                              onClick={() => setShowLogin(true)}
                            >
                              Log in
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                setShowSignUp(true);
                              }}
                              className={classNames(
                                active ? "hover:brand-color-opaque" : "",
                                "block border-t-2 px-4 py-4 text-center text-sm font-semibold text-black",
                              )}
                            >
                              Sign up
                            </a>
                          )}
                        </Menu.Item>
                      </>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </Disclosure>
    </>
  );
}
