import React, { useEffect, useState } from "react";
import FilterPopup from "./FilterPopup";
import useClickOutside from "src/hooks/useClickOutside";
import { useFilterContext } from "../../contexts/FilterContext/FilterContext";
import Image from "next/image";

const DealPriceFilter = () => {
  const { filterDispatch } = useFilterContext();
  const [form, setForm] = useState({ min: 0, max: 999 });
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(999);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleToggleFilter = () => setIsFilterOpen((prev) => !prev);

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
    handleBlur();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.name === "min") {
      setForm({ ...form, min: parseInt(value) });
    } else {
      setForm({ ...form, max: parseInt(value) });
    }
  };

  const handleBlur = () => {
    if (form.max > 999 || isNaN(form.max)) {
      setForm({ ...form, max: 999 });
      setMax(999);
    }

    if (form.min < 0 || isNaN(form.min)) {
      setForm({ ...form, min: 0 });
      setMin(0);
    }

    if (form.min > form.max) {
      setForm({ max: Math.min(form.min, 999), min: Math.max(form.max, 0) });
      setMin(Math.max(form.max, 0));
      setMax(Math.min(form.min, 999));
    } else {
      setMin(form.min || 0);
      setMax(form.max || 999);
    }
  };

  useEffect(() => {
    filterDispatch({ type: "SET_MIN_PRICE", payload: min });
    filterDispatch({ type: "SET_MAX_PRICE", payload: max });
  }, [min, max]);

  const getButtonTitle = (): string => {
    if (min === 0 && max === 999) {
      return "Any Price";
    } else if (min === 0 && max === 0) {
      return "Free";
    } else if (min === 0) {
      return `Under $${max}`;
    } else if (max === 999) {
      return `Over $${min}`;
    } else {
      return `$${min} - $${max}`;
    }
  };

  const buttonTitle = getButtonTitle();

  const { ref, exceptionRef } = useClickOutside(handleCloseFilter);
  return (
    <div className="h-11 max-w-[160px] text-sm sm:text-base flex-1 md:relative">
      <div
        className="flex h-full  cursor-pointer items-center justify-center ml-1"
        onClick={handleToggleFilter}
        ref={exceptionRef} 
      >
         <Image
          src="/filter_icon.svg"
          alt="filter"
          width={24}
          height={20}
        />
      </div>
      {isFilterOpen && (
        <div ref={ref}>
          <FilterPopup
            align="right"
            width="w-[calc(100vw-50px)] ml-6 md:ml-0 md:w-96"
          >
            <div className="flex w-full flex-col gap-y-2 p-4">
              <div className="flex max-w-full justify-between gap-4 md:gap-2">
                <div className="flex h-20 max-w-[50%] flex-wrap items-center justify-center gap-2 md:flex-col">
                  <p className="w-full text-left">Minimum</p>
                  <div className="flex h-12 w-full items-center rounded-lg bg-neutral-50 pl-2 shadow">
                    $
                    <input
                      value={form.min}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleBlur();
                        }
                      }}
                      name="min"
                      type="number"
                      className="w-full border-none bg-transparent focus:border-transparent focus:outline-none focus:ring-0"
                    />
                  </div>
                </div>
                <div className="flex h-20 max-w-[50%] flex-wrap items-center justify-center gap-2 md:flex-col">
                  <p className="w-full text-left">Maximum</p>
                  <div className="flex h-12 w-full items-center rounded-lg bg-neutral-50 pl-2 shadow">
                    $
                    <input
                      value={form.max}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleBlur();
                        }
                      }}
                      name="max"
                      type="number"
                      className="w-full border-none bg-transparent focus:border-transparent focus:outline-none focus:ring-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </FilterPopup>
        </div>
      )}
    </div>
  );
};

export default DealPriceFilter;
