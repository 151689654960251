import React, { createContext, useState, useContext, ReactNode } from 'react';
import { BreadCrumbNavProps } from '../nav/BreadCrumbNav';

export type PageContextType = {
  isHiddenHeader: boolean;
  setIsHiddenHeader: (value: boolean) => void;
  isHiddenFooter: boolean;
  setIsHiddenFooter: (value: boolean) => void;
  breadcrumb: BreadCrumbNavProps;
  setBreadcrumb: (value: BreadCrumbNavProps) => void;
  pageTitle: string;
  setPageTitle: (value: string) => void;
};

const defaultValue = {
  isHiddenHeader: false,
  isHiddenFooter: false,
  breadcrumb: { label: '' },
  pageTitle: '',
  setPageTitle: () => '',
  setBreadcrumb: () => undefined,
  setIsHiddenHeader: () => false,
  setIsHiddenFooter: () => false,
};

export const PageContext = createContext<PageContextType>(defaultValue);

export const PageProvider = ({ children }: { children: ReactNode }) => {
  const [isHiddenHeader, setIsHiddenHeader] = useState(false);
  const [isHiddenFooter, setIsHiddenFooter] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState<BreadCrumbNavProps>({ label: '' });
  const [pageTitle, setPageTitle] = useState('');

  return (
    <PageContext.Provider
      value={{ isHiddenHeader, setIsHiddenHeader, isHiddenFooter, setIsHiddenFooter, breadcrumb, setBreadcrumb, pageTitle, setPageTitle }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext<PageContextType>(PageContext);
