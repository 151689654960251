/* eslint-disable @next/next/no-html-link-for-pages */
import { Menu } from "@headlessui/react";
import Link from "next/link";
import {
  AccountContext,
  AccountContextType,
} from "../contexts/ToggleAccountContext";
import { Fragment, useContext } from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavLoggedInMenu({
  unreadMessages,
}: {
  unreadMessages: number;
}) {
  const { logout, checkHasNewFeed, setCheckHasNewFeed } = useContext<AccountContextType>(AccountContext);
  return (
    <Fragment>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/opportunities"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block px-4 py-4 text-center text-sm font-semibold text-black"
            )}
          >
            Opportunities
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/community"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block border-t-2 px-4 py-4 text-center text-sm font-semibold text-black"
            )}
          >
            <div className="relative">
              Community
              {checkHasNewFeed && (
                <div className="brand-color-semi-transparent absolute top-[3px] right-1 inline-flex h-3 w-3 rounded-full"></div>
              )}
            </div>
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/messages"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              " block  border-t-2 px-4 py-4 text-center text-sm font-semibold text-black"
            )}
          >
            <div className="relative">
              Messages
              {unreadMessages > 0 && (
                <div className="brand-color-semi-transparent absolute top-[3px] right-1 inline-flex h-3 w-3 rounded-full"></div>
              )}
            </div>
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/account"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block border-t-2  px-4 py-4 text-center text-sm text-black"
            )}
            
          >
            Account
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block w-full border-t-2 px-4 py-4 text-center text-sm text-black"
            )}
            onClick={() => {
              logout();
              window.location.pathname === "/";
            }}
          >
            Log out
          </button>
        )}
      </Menu.Item>
    </Fragment>
  );
}
