import { STT_OK, STT_EMPTY, STT_NOT_FOUND, STT_DUPLICATED } from "src/const/app.const";
import { createApi } from ".";
import { Business, BusinessPublic, BusinessSimple, Team } from "src/types/models";
import toast from 'react-hot-toast';
import { STT_UNAUTHORIZED} from "src/const/app.const";

interface IBusiness {
  name: string;
  website: string;
  email: string;
}

interface IUpdateBusiness {
  name: string;
  website: string;
  email: string;
  image: string | undefined;
  description: string;
  opportunityId: string;
  businessId: string;
  rating: number;
  stripeCode: string;
}

export const getBusinessInfo = async (id: string) => {
  const response = await createApi().get<{
    result: Business;
    message?: string;
  }>(`/business/${id}`);

  if (response.status === STT_NOT_FOUND) {
    // Error silent - when we delete an event it happens some how
    return false;
  }

  if (response.status !== STT_OK || !response.data) {
    // toast.error((response as { message?: string })?.message || "Internal Error");
    return false;
  }
  return response.data?.result;
};

export const createBusiness = (info: IBusiness) => createApi().post("/business", info);

export const addRating = async (data: {
  rating: number;
  orderId: string;
  description: string;
  media: string[];
}) => {
  const res = await createApi().post<{
    ok: true;
    message?: string;
  }>("/rating", data);

  if (res.status === STT_EMPTY) {
    return false;
  }
  if (res.status !== STT_OK) {
    // toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};

export const updateBusinessInfo = (id: string, data: Partial<IUpdateBusiness>) =>
  createApi().put(`/business/${id}`, data);

export const deleteBusiness = async (id: string) => {
  const res = await createApi().delete<{ message: string; ok: true }>(`/business/${id}`);
  if (res?.data?.message || !res.data) {
    toast.error(res.data?.message || "Something went wrong.");
    return false;
  }
  return true;
};

export const addTeamMember = async (data: {
  businessId: string;
  userId: string;
  role: string;
  locationId?: string;
}) => {
  const res = await createApi().post<{
    result: Team;
    message: string;
    ok: true;
  }>(`/business/members`, data);

  if (res?.data?.message || !res.data) {
    toast.error(res.data?.message || "Something went wrong.");
    return false;
  }

  return res.data.result;
};

export const deleteTeamMember = async (data: {
  businessId: string;
  userId: string;
  locationId?: string;
}) => {
  const res = await createApi().delete<{ message: string; ok: true }>(`/business/members`, {
    businessId: data.businessId,
    userId: data.userId,
    locationId: data.locationId,
  });

  if (res?.data?.message || !res.data) {
    toast.error(res.data?.message || "Something went wrong.");
    return false;
  }

  return true;
};

export const connectWithStripe = async (businessId: string, returnUrl: string) => {
  const res = await createApi().post<{ url: string; message: string }>(`/business/stripe`, {
    businessId,
    returnUrl,
  });

  // It is already connected. We reload
  if (res.status === STT_DUPLICATED) {
    window.location.reload();
    return undefined;
  }
  if (res?.status !== STT_OK || !res.data) {
    toast.error(res.data?.message || "Something went wrong.");
    return undefined;
  }

  return res.data.url;
};

export const getBusinessPublicInfo = async (id: string, accountInfoBusinessId?: string | undefined): Promise<false | BusinessPublic> => {
  try {
    const response = await createApi().get<{
      result: BusinessPublic;
      message?: string;
    }>(`/business-info/${id}`, { accountInfoBusinessId });

    if (response.status === STT_NOT_FOUND) {
      // Error silent - when we delete an event it happens somehow
      return false;
    }

    if (response.status !== STT_OK || !response.data) {
      // toast.error(
      //   (response as { message?: string })?.message || "Internal Error"
      // );
      return false;
    }

    return response.data?.result as BusinessPublic;
  } catch (error) {
    console.error('Error fetching business info:', error);
    return false;
  }
};

export const getBusinessSimplePublicInfo = async (id: string, accountInfoBusinessId?: string | undefined): Promise<false | BusinessSimple> => {
  try {
    const response = await createApi().get<{
      result: BusinessSimple;
      message?: string;
    }>(`/business-simpleinfo/${id}`, { accountInfoBusinessId });

    if (response.status === STT_NOT_FOUND) {
      // Error silent - when we delete an event it happens somehow
      return false;
    }

    if (response.status !== STT_OK || !response.data) {
      // toast.error(
      //   (response as { message?: string })?.message || "Internal Error"
      // );
      return false;
    }

    return response.data?.result as BusinessSimple;
  } catch (error) {
    console.error('Error fetching business info:', error);
    return false;
  }
};

export const getFeedByBusinessId = async(businessId: string, data: { limit: number; offset: number; accountInfoBusinessId: string; accountInfoUserId: string }) => {
  const res = await createApi().get<any>(`/feed/business/${businessId}`, { params: data });
  if ((res.status !== STT_OK) && res.status !== STT_UNAUTHORIZED) {
    // toast.error(`${res.data?.message || res.originalError}`);
    return; }
    return res.data;
};
