import React, { useState, createContext, ReactNode } from "react";

// Define the enum for feed type
export enum FeedType {
  HOT = "hot",
  NEW = "new",
  JAZZED = "jazzed",
  AROUND = "around",
  PRIME = "prime",
  EVENT = "event",
  GIFT_CARD = "giftcard",
}

export type OpportunityContextType = {
  feed: FeedType;
  setFeed: (value: FeedType) => void;
  currentOption: number;
  setCurrentOption: (index: number) => void;
  showSignUpModel: boolean;
  setShowSignUpModel: (value: boolean) => void;
};

export const OpportunityContext = createContext<OpportunityContextType>({
  feed: FeedType.NEW,
  setFeed: () => 0,
  currentOption: 0,
  setCurrentOption: () => 0,
  showSignUpModel: false,
  setShowSignUpModel: () => false,
});

export const OpportunityProvider = ({ children }: { children: ReactNode }) => {
  const [feed, setFeed] = useState(FeedType.NEW);
  const [showSignUpModel, setShowSignUpModel] = useState(false);
  const [currentOption, setCurrentOption] = useState(0);

  return (
    <OpportunityContext.Provider
      value={{
        feed,
        setFeed,
        showSignUpModel,
        setShowSignUpModel,
        currentOption,
        setCurrentOption,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};
