import { MouseEventHandler } from "react";

export const AddIcon = (props: { onClick?: () => void }) => {
  return (
    <svg
      onClick={props.onClick}
      width="25"
      height="25"
      className={"cursor-pointer active:opacity-60"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.65 34H25.65V25.7H34V22.7H25.65V14H22.65V22.7H14V25.7H22.65V34ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7167 4 23.95C4 21.2167 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2833 4 24.05 4C26.7833 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44ZM24.05 41C28.75 41 32.75 39.3417 36.05 36.025C39.35 32.7083 41 28.6833 41 23.95C41 19.25 39.35 15.25 36.05 11.95C32.75 8.65 28.7333 7 24 7C19.3 7 15.2917 8.65 11.975 11.95C8.65833 15.25 7 19.2667 7 24C7 28.7 8.65833 32.7083 11.975 36.025C15.2917 39.3417 19.3167 41 24.05 41Z"
        fill="black"
      />
    </svg>
  );
};

export const CloseIcon = ({
  onClick,
  size = 16,
  color = "black",
}: {
  onClick: (e: MouseEvent) => void;
  size?: number;
  color?: string;
}) => (
  <div className="rounded-full hover:bg-gray-200" onClick={(e: any) => onClick(e)}>
    <svg
      onClick={(e: any) => onClick(e)}
      width={size}
      height={size}
      className="m-1 cursor-pointer"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6668 2.6835L21.3168 0.333496L12.0002 9.65016L2.6835 0.333496L0.333496 2.6835L9.65016 12.0002L0.333496 21.3168L2.6835 23.6668L12.0002 14.3502L21.3168 23.6668L23.6668 21.3168L14.3502 12.0002L23.6668 2.6835Z"
        fill={color}
      />
    </svg>
  </div>
);

export const TrashIcon = ({ onClick }: { onClick: () => void }) => (
  <svg
    width="25"
    height="25"
    className="cursor-pointer active:opacity-60"
    onClick={onClick}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.05 42C12.2167 42 11.5083 41.7083 10.925 41.125C10.3417 40.5417 10.05 39.8333 10.05 39V10.5H8V7.5H17.4V6H30.6V7.5H40V10.5H37.95V39C37.95 39.8 37.65 40.5 37.05 41.1C36.45 41.7 35.75 42 34.95 42H13.05ZM34.95 10.5H13.05V39H34.95V10.5ZM18.35 34.7H21.35V14.75H18.35V34.7ZM26.65 34.7H29.65V14.75H26.65V34.7ZM13.05 10.5V39V10.5Z"
      fill="black"
    />
  </svg>
);
