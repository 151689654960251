import { createSlice } from "@reduxjs/toolkit";
import { setOpportunityType } from "./action";

const initialState = {
  opportunityType: "",
};

const opportunitySlice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    resetOpportunityState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setOpportunityType.fulfilled, (state, action) => {
      state.opportunityType = action.payload;
    });
  },
});

export const { resetOpportunityState } = opportunitySlice.actions;
export default opportunitySlice.reducer;
