import {
    STT_OK,
    STT_CREATED,
  } from "src/const/app.const";
  import { createApi } from ".";
  import toast from 'react-hot-toast';
import { Reaction } from "src/types/models";
  
  interface ICreateReaction {
    targetId: string;
    targetType: string;
    reactionId: string;
    content?: string;
  }

  interface ICreateReactionBussiness {
    targetId: string;
    targetType: string;
    reactionId: string;
    content?: string;
    businessId:string;
  }

  export const addReactions = async (data: ICreateReaction) => {
   
    const res = await createApi().post<Reaction | {message: string}>("/reaction", data);
    if(res.data && "message" in res.data) {
      toast.error(res.data?.message || "Sorry, something went wrong.");
    }
    if (res.status !== STT_CREATED) {
      toast.error( "Sorry, something went wrong.");
    }  
    return res.data;
  };

  export const addReactionsBusiness = async (data: ICreateReactionBussiness) => {
    const res = await createApi().post<Reaction | {message: string}>("/reaction/business", data);
    if(res.data && "message" in res.data) {
      toast.error(res.data?.message || "Sorry, something went wrong.");
    }
    if (res.status !== STT_CREATED) {
      toast.error( "Sorry, something went wrong.");
    }  
   
    return res.data;
  };

  export const GetReactions = async (data) => {
    const res = await createApi().get<any>(`/reaction/feed`, data);
    if (res.status === STT_OK) {
      return res.data;
    } else {
      // toast.error("Invalid reaction");
      return null;
    }
  };

  export const DeleteReactions = async (data: {
    targetId: string;
    reactionId: string;
  }) => {
    
      const res = await createApi().delete<{ message: string; ok: true }>(`/reaction`, {},{
       data
      });
    
      if (res.status !== STT_OK) {
        // toast.error("Invalid reaction");
        return false;
      }
      return true;
 }

  export const DeleteReactionsBusiness = async (data: {
    targetId: string;
    reactionId: string;
    businessId:string;
  }) => { 
      const res = await createApi().delete<{ message: string; ok: true }>(`/reaction/business`, {},{
       data
      });    


        if (res.status !== STT_OK) {
          // toast.error("Invalid reaction");
          return false;
        }
        return true;
  };

