import { createAsyncThunk } from "@reduxjs/toolkit";
import { IEventDates } from "src/components/CreateEventForm/EventDates";

export const setCurrentStep = createAsyncThunk(
  "deal/setCurrentStep",
  async (step: any) => {
    return step;
  }
);
 
export const setLocation = createAsyncThunk(
  "deal/setLocation",
  async (location: any) => {
    return location;
  }
);
 
export const setType = createAsyncThunk("deal/setType", async (type: any) => {
  return type;
});

export const setDescription = createAsyncThunk(
  "deal/setDescription",
  async (description: string) => {
    return description;
  }
);

export const setDraftId= createAsyncThunk(
  "deal/",
  async (draftId: string) => {
    return draftId;
  }
);

export const setCanUseChatGPT = createAsyncThunk(
  "deal/setCanUseChatGPT",
  async (canUseChatGPT: boolean) => {
    return canUseChatGPT;
  }
);

export const setPrice = createAsyncThunk(
  "deal/setPrice",
  async (price: number) => {
    return price;
  }
);

export const setDiscount = createAsyncThunk(
  "deal/setDiscount",
  async (discount: number) => {
    return discount;
  }
);

export const setIsFreeEvent = createAsyncThunk(
  "event/setIsFreeEvent",
  async (isFreeEvent: boolean) => {
    return isFreeEvent;
  }
);

export const setEventDate = createAsyncThunk(
  "event/setEventDate",
  async (eventDates: IEventDates[]) => {
    return eventDates;
  }
);

export const setEventTitle = createAsyncThunk(
  "event/setTitle",
  async (title: string) => {
    return title;
  }
);