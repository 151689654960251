import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

export interface ModalProps {
  open: boolean;
  title?: string;
  subTitle?: string;
  closeButton?: boolean;
  children?: React.ReactNode;
  className?: string;
  align?:string;
  onClose: () => void;
}

const NewModal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  subTitle,
  closeButton,
  children,
  className,
  align
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const body = document.body;
    if (open) {
      body.style.overflow = "hidden";
      body.style.touchAction = "none";
    } else {
      body.style.overflow = "auto";
      body.style.touchAction = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      body.style.touchAction = "auto";
    };
  }, [open]);

  const closeModal = useCallback(() => {
    const body = document.body;
    body.style.overflow = "auto";
    body.style.touchAction = "auto";
    onClose();
  }, [onClose]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, closeModal]);

  if (!open) {
    return null;
  }

  return (
    <>
      {open &&
        createPortal(
          <>
            <div
              ref={wrapperRef}
              className={twMerge(
                `modal-layer fixed inset-0 z-50 m-auto flex flex-col ${align ? "overflow-y  sm:overflow-y-hidden " :"items-center"  }  bg-[#FBFBFB] px-8 py-6 md:h-fit md:w-[65%] md:rounded-lg lg:w-[40%] ${className}`
              )}
              style={{
                left: `${onClose.pageX - 200}px`,
                top: `${onClose.pageY}px`,
              }}
            >
              <div className="relative flex w-full justify-start">
                <div>
                  <h1 className="text-left text-2xl font-bold">{title}</h1>
                  <h2 className="text-left text-xl font-medium text-[#403F3F]">
                    {subTitle}
                  </h2>
                </div>
                {closeButton && (
                  <div
                    className="absolute right-0 top-3 z-10 -translate-y-[13px] cursor-pointer rounded-full p-2"
                    onClick={closeModal}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 28 28"
                      fill="none"
                      className="flex flex-shrink-0 items-center"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.6004 1.42043C25.8204 0.64043 24.5604 0.64043 23.7804 1.42043L14.0004 11.1804L4.22043 1.40043C3.44043 0.62043 2.18043 0.62043 1.40043 1.40043C0.62043 2.18043 0.62043 3.44043 1.40043 4.22043L11.1804 14.0004L1.40043 23.7804C0.62043 24.5604 0.62043 25.8204 1.40043 26.6004C2.18043 27.3804 3.44043 27.3804 4.22043 26.6004L14.0004 16.8204L23.7804 26.6004C24.5604 27.3804 25.8204 27.3804 26.6004 26.6004C27.3804 25.8204 27.3804 24.5604 26.6004 23.7804L16.8204 14.0004L26.6004 4.22043C27.3604 3.46043 27.3604 2.18043 26.6004 1.42043Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {children}
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
          </>,
          document.body
        )}
    </>
  );
};

export default NewModal;
