import { FaSpinner } from "react-icons/fa";
import { twMerge } from "tailwind-merge";

const SubmitButton = ({
  onClick,
  label,
  loading,
  disabled,
  className,
  title,
  loadingContent, // New prop to customize loading content
}: {
  onClick?: () => void;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  title?: string;
  loadingContent?: React.ReactNode; // Custom loading content (logo as animation)
} & React.HTMLProps<HTMLButtonElement>) => {
  const classes = disabled
    ? "text-white bg-gray-300 cursor-not-allowed"
    : "brand-color text-white";

  //spinner as animation
  const defaultLoadingContent = (
    <span className="text-center">
      <FaSpinner className="inline-block w-full animate-spin" />
    </span>
  );



  return (
    <button
      type="submit"
      disabled={disabled}
      className={twMerge(
        classes,
        "m-auto mt-4 block w-full rounded py-2 px-2 text-center text-lg font-semibold cursor-pointer",
        className
      )}
      title={title}
      onClick={!loading && !disabled ? onClick : undefined}
    >
      {loading ? (loadingContent || defaultLoadingContent) : label}
    </button>
  );
};

export default SubmitButton;
