import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { EMAIL_REGEX, getEmailErrorMessage } from "src/const/app.const";
import { AccountService } from "src/services";
import { FaSpinner } from "react-icons/fa";
import SubmitButton from "src/components/common/SubmitButton";
import NewModal from "../common/Modal-Redesign";
import Image from "next/image";
import NewCommonInput from "../common/CommonInput-Redesign";
import { useAccountContext } from "../contexts/ToggleAccountContext";
interface SignUpModalProps {
  open: boolean;
  onClose: () => void;
  onLogin: (flag: boolean) => void;
}

const SignUpModal: React.FC<SignUpModalProps> = ({
  open,
  onClose,
  onLogin,
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const { setShowLogin } = useAccountContext();
  useEffect(() => {
    const body = document.body;
    body.style.overflow = "auto"; // enable scrolling on the body element
  }, [open]);

  const handleContinue = async () => {
    setLoading(false);
    const data = await AccountService.emailAvailable(currentEmail);
    if (!data) {
      setLoading(false);
      return;
    }
    setLoading(false);
    onClose();
    router.push(`/sign-up?e=${Buffer.from(currentEmail).toString("base64")}`);
  };

  useEffect(() => {
    return () => setCurrentEmail("");
  }, []);

  return (
    <>
      {open ? (
        <>
          <NewModal
            open={open}
            onClose={() => {
              setCurrentEmail("");
              onClose();
            }}
            className="max-w-[800px] px-0 py-0 sm:min-w-[650px]"
          >
            <div className="flex min-h-[100vh] w-full justify-center md:min-h-[460px]">
              <div className="hidden h-full w-[45%] rounded-l-lg sm:block">
                <div className="absolute left-4 top-5 z-10 max-w-[45%] text-3xl font-semibold text-white">
                  Welcome to <br />
                  <span className="brand-text-color font-bold">MindKind</span>
                </div>
                <div className="relative h-full w-full rounded-l-lg">
                  <img
                    src="/signup-side-image-min.jpg"
                    alt="Community Image"
                    loading="lazy"
                    className="z-0 min-h-[100vh] w-full rounded-l-lg object-cover md:min-h-[460px]"
                  />
                </div>
                <div className="absolute top-0 left-0 h-full w-[45%]">
                  <Image
                    src="/NewLogo.svg"
                    alt="MindKind Logo"
                    width={40}
                    height={40}
                    className="z-3 absolute right-2 bottom-2"
                  />
                </div>
                <div className="z-2 absolute top-0 left-0 h-full w-[45%] rounded-l-lg bg-black opacity-25" />
              </div>
              <div className="flex w-full flex-col items-center p-8 sm:w-[55%]">
                <div
                  className="close-button absolute right-3 top-6 z-10 -translate-y-[13px] cursor-pointer rounded-full p-2"
                  onClick={(e) => {
                    onClose();
                  }}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 28 28"
                    fill="none"
                    className="flex flex-shrink-0 items-center"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.6004 1.42043C25.8204 0.64043 24.5604 0.64043 23.7804 1.42043L14.0004 11.1804L4.22043 1.40043C3.44043 0.62043 2.18043 0.62043 1.40043 1.40043C0.62043 2.18043 0.62043 3.44043 1.40043 4.22043L11.1804 14.0004L1.40043 23.7804C0.62043 24.5604 0.62043 25.8204 1.40043 26.6004C2.18043 27.3804 3.44043 27.3804 4.22043 26.6004L14.0004 16.8204L23.7804 26.6004C24.5604 27.3804 25.8204 27.3804 26.6004 26.6004C27.3804 25.8204 27.3804 24.5604 26.6004 23.7804L16.8204 14.0004L26.6004 4.22043C27.3604 3.46043 27.3604 2.18043 26.6004 1.42043Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="w-full max-w-[400px]">
                  <div className="flex w-full flex-col gap-2 rounded-[5px]">
                    <h1 className="text-2xl font-medium">Sign up</h1>
                    <h3 className="mb-2 font-medium text-[#343434]">
                      Welcome bonus up to $200
                    </h3>
                    <NewCommonInput
                      name="email"
                      label="Email"
                      value={currentEmail}
                      onChangeValue={setCurrentEmail}
                      className="shadow-none"
                      error={getEmailErrorMessage(currentEmail)}
                    />
                    <div className="flex flex-col gap-1 px-2 pb-2 text-sm">
                      <p className="text-[#9B9A9A]">
                        Please provide your personal email address.
                      </p>
                      <p className="text-[#9B9A9A]">
                        If you are a business owner, you will be able to create
                        a separate business profile later.
                      </p>
                    </div>
                  </div>
                  {loading ? (
                    <div className="brand-color mt-4 w-full rounded-md py-2">
                      <p className="text-center text-lg text-white">
                        <FaSpinner className="inline-block w-full animate-spin " />
                      </p>
                    </div>
                  ) : (
                    <SubmitButton
                      disabled={!Boolean(currentEmail.match(EMAIL_REGEX))}
                      label="Continue"
                      onClick={handleContinue}
                    />
                  )}
                </div>
                <div className="flex w-full max-w-[400px] items-center justify-center py-5">
                  <div className="h-[1px] w-[30%] bg-black" />
                  <p className="px-2 font-semibold">or</p>
                  <div className="h-[1px] w-[30%] bg-black" />
                </div>
                <button
                  onClick={() => {
                    setShowLogin(true);
                    onClose();
                  }}
                  className="mb-2 w-full max-w-[400px] rounded-md border border-[#EAAC00] py-2 px-2 font-bold text-[#eaac00]"
                >
                  Log in
                </button>
              </div>
            </div>
          </NewModal>
        </>
      ) : null}
    </>
  );
};

export default SignUpModal;
