import toast from 'react-hot-toast';
import { Socket, io } from "socket.io-client";

const SOCKET_EVENTS = {
  LIVE_MESSAGE: "live-message",
  RESPONSE: "response",
  TOAST: "toast",
  WEDNESDAY_WINS: "wednesday-wins",
};
// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NEXT_PUBLIC_API_ENDPOINT || "http://localhost:3001";
const url = URL.replace("localhost", "127.0.0.1")
  .replace(/https?:\/\//, "")
  .replace("/api", "");

export const STORAGE_TOKEN = "token";
let socket: Socket;
export class SocketClient {
  static connect(): Promise<Socket> {
    if (socket && socket.connected) {
      return Promise.resolve(socket);
    }
    socket = io(url, {
      autoConnect: false,
      transports: ["websocket"],
      auth: {
        token: localStorage.getItem(STORAGE_TOKEN) || "",
      },
    });

    return new Promise((resolve) => {
      socket.connect();

      /**
       * Generic Event Handlers
       * Specific business handlers shall be set up in his own function
       */
      socket.on("connect", () => {
        console.info("[Socket] Connected");
      });
      socket.on("disconnect", () => {
        console.info("[Socket] Disconnected");
      });
      socket.on("connect_failed", function (err) {
        console.error("[Socket] connection failed!", err.message);
      });
      socket.on("connect_error", function (err) {
        console.error("[Socket] connection rejected!", err.message);
      });
      socket.on("reconnect_failed", function () {
        console.error("[Socket] reconnect failed!");
      });
      socket.on(SOCKET_EVENTS.RESPONSE, (data) => {
        console.info(`[Socket] Response [${data.event}]: ${data.message}`);
      });

      resolve(socket);
    });
  }
  static disconnect() {
    if (!socket || !socket.connected) {
      return Promise.resolve(true);
    }
    socket.disconnect();
    return Promise.resolve(true);
  }

  static listenEvents(id: string, callback: () => void) {
    if (!socket) {
      throw new Error("Socket not connected");
    }
    socket.on("connect", () => {
      if (!socket) {
        return;
      }
      socket.emit(SOCKET_EVENTS.LIVE_MESSAGE, {
        data: { id },
      });
    });
    socket.on(SOCKET_EVENTS.LIVE_MESSAGE, callback);
    socket.on(SOCKET_EVENTS.TOAST, (data) => {
      toast(data.message, { autoClose: 3000 });
    });
  }

  static async joinWednesdayWins(
    data: { userId: string; username: string; roomName: string },
    callback: (data: any, eventName: string) => void
  ) {
    if (!socket) {
      await this.connect();
    }

    socket.emit(SOCKET_EVENTS.WEDNESDAY_WINS, {
      data,
    });
    socket.on("connection_rejected", function (err) {
      console.error("[Socket] Hack room connection rejected!", err);
      callback(err, "connection_rejected");
    });
    socket.on("info", function (data) {
      console.info("[Socket] Info", data);
      callback(data, "info");
    });
    socket.on("hack-winner", function (data) {
      console.info("[Socket] Winning user", data);
      callback(data, "winner");
    });
    socket.on("winner-selected", function (data) {
      console.info("[Socket] Winner selected", data);
      callback(data, "winner-selected");
    });
    socket.on("room-closed", function (data) {
      console.info("[Socket] Room closed", data);
      callback(data, "room-closed");
    });
  }

  static get socket() {
    return socket;
  }
}
