import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Disclosure } from "@headlessui/react";
import { NextRouter, useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { FiShare } from "react-icons/fi";
import toast from 'react-hot-toast';

export type BreadCrumbNavProps = {
  label: string;
  noBack?: boolean;
  onBack?: (router: NextRouter) => void;
};

export default function BreadCrumbNav({
  label,
  onBack,
  noBack,
}: BreadCrumbNavProps) {
  const router = useRouter();
  const [hasHistory, setHasHistory] = useState(false);

  useEffect(() => {
    setHasHistory(window.history.length > 1);
  }, []);

  const isOpportunityPage =
    router.pathname.includes("/opportunity");

  const isDetailPage =
    router.pathname.includes("/detail");

  const isPreviewPage =
    router.pathname.includes("/preview");
   

  const isPostPage =
    router.pathname.includes("/community/post");

  const isMemberPage = 
    router.pathname.includes("/member") ||
    router.pathname.includes("/member-business");
  
    const goBack = useCallback(() => {
      if (onBack) {
        return onBack(router);
      }
  
      if (hasHistory) {
        router.back();
      } else {
        router.push("https://mindkind.life");
      }
    }, [onBack, router, hasHistory]);
  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Link copied");
  };

  return (
    <Disclosure
      as="nav"
      className="navbar flex min-h-[73px] w-full items-center border border-b bg-gray-50"
    >
      <div
        className={`${
          isOpportunityPage || isDetailPage || isPostPage || isPreviewPage ? "gap-2" : "mx-auto"
        } flex w-full items-center px-2 sm:px-6 lg:px-8`}
      >
        {!noBack && (
          <div
            className={`${
              isOpportunityPage || isDetailPage || isPostPage || isPreviewPage ? "" : "absolute top-3 left-2"
            } cursor-pointer rounded-full p-2`}
            onClick={goBack}
          >
            <ArrowBackIosNewIcon />
          </div>
        )}
        <div
          className={`flex h-16 ${
            isDetailPage ? "w-[220px]"
            : isOpportunityPage ? "w-[calc(100%-180px)]" : "w-full"
          } items-center justify-center`}
        >
          <span
            className={`${
              isOpportunityPage || isDetailPage || isPostPage ? "text-left text-lg" : isMemberPage ? "px-8 text-center text-xl" : "text-center text-xl"
            } w-full font-medium`}
          >
            {label}
          </span>
        </div>
        {isMemberPage && (
          <FiShare
            className="fixed top-0 right-3 z-40 flex h-[73px] w-[27px] cursor-pointer text-[#e4d5aa] flex-row-reverse items-center justify-center"
            onClick={handleShare}
          />
        )}
      </div>
    </Disclosure>
  );
}