import React, { useEffect, useRef } from "react";

const useClickOutside = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClick(event: any) {
      if (
        ref.current &&
        ref.current.contains &&
        !ref.current.contains(event.target) &&
        (!exceptionRef ||
          !exceptionRef.current ||
          !exceptionRef.current.contains(event.target))
      ) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, exceptionRef, callback]);

  return { ref, exceptionRef };
};

export default useClickOutside;
