import { useCallback, useContext, useEffect, useState } from "react";
import {
  AccountContext,
  AccountContextType,
} from "../contexts/ToggleAccountContext";
import { SocketClient } from "src/services/socketio.service";
import { Message } from "src/types/models";
import toast from 'react-hot-toast';
import { updateMailBox } from "src/redux/message.reducer";
import { useDispatch } from "react-redux";

export default function LiveMessages() {
  const { userInfo, isBusiness } =
    useContext<AccountContextType>(AccountContext);
  const dispatch = useDispatch();

  const [subscribed, setSubscribed] = useState(false);

  const onMessage = useCallback(
    (data: { message: Message; toast: boolean }) => {
      if (data.message.target === "business" && isBusiness) {
        data.toast && toast(data.message.title, { autoClose: 3000 });
        dispatch(updateMailBox({ message: data.message }));
      }
      if (data.message.target === "user" && !isBusiness) {
        data.toast && toast(data.message.title, { autoClose: 3000 });
        dispatch(updateMailBox({ message: data.message }));
      }
    },
    [dispatch, isBusiness]
  );

  useEffect(() => {
    function cleanup() {
      SocketClient.disconnect();
    }
    if (!userInfo) {
      setSubscribed(false);
      return cleanup();
    }
    if (subscribed) {
      return;
    }
    setSubscribed(true);
    SocketClient.connect();
    SocketClient.listenEvents(userInfo.id, onMessage as () => void);
    return;
  }, [userInfo, onMessage, subscribed]);
  return null;
}
