import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";

import { useRouter } from "next/router";
import { AccountContext, AccountContextType } from "src/components/contexts/ToggleAccountContext";
import Image from "next/image";
import { CommunityService } from "src/services";
export default function Community() {
    const router = useRouter();
    const { isBusiness, accountInfo, userInfo, checkHasNewFeed, setCheckHasNewFeed } =
        useContext<AccountContextType>(AccountContext);
    // const [checkHasNewFeed, setCheckHasNewFeed] = useState(false)
    const redirectToCommunity = () => {
        router.push("/community");
    };

    const checkHasNew = useCallback(async () => {
        if (!accountInfo || isBusiness) {
            return;
        }
        const data = {
            limit: 1,
            offset: 0,
            businessId: isBusiness ? accountInfo.id : undefined
        };
        const community = await CommunityService.getCommunity(accountInfo.id, isBusiness)

        if (!community.data[0]) {
            return
        }
        const communityFeedData = await CommunityService.getCommunityFeed(community?.data[0].communityId, data);

        if (communityFeedData?.feed[0].createdAt > userInfo?.lastTimeCheckCommunity) {
            setCheckHasNewFeed(true)
            return
        }
            setCheckHasNewFeed(false)
        //dispatch(updateMailBox({ amount: data.newMessages }));
    }, [accountInfo, isBusiness]);
    // Check on load and after a new message
    useEffect(() => {
        checkHasNew();
    }, [accountInfo]);

    return (
        <div className="h-11 max-w-[160px] ml-5 text-sm sm:text-base flex-1 md:relative">
            <div
                className="flex h-full cursor-pointer items-center justify-center ml-1 relative"
                onClick={redirectToCommunity}
            >
                <Image
                    src="/icons/vote-icon.svg"
                    alt="filter"
                    width={22}
                    height={22}
                />
                {/* Dot for notifications */}
                {checkHasNewFeed && (
                    <div className="brand-color-semi-transparent absolute -top-0 -right-1 inline-flex h-3 w-3 rounded-full">
                        {/* {unreadMessages} */}
                    </div> 
                )}
            </div>
        </div>
    );
};