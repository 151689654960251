import React, { createContext, useContext, useMemo, useReducer, useState } from "react";
import filterReducer, { FilterAction, FilterState, initialFilterState } from "./filterReducer";
import { OpportunityPreview } from "src/types/models";
export type SearchParams = Omit<FilterState, "type"> & { type: string };

const initialSearchParams: SearchParams = {
  ...initialFilterState,
  type: "",
};

export const categoryMap: { [key: string]: string } = {
  all: "",
  "art-studio": "Art Studio",
  "auto-shop": "Auto Shop",
  bakery: "Bakery",
  bar: "Bar",
  "beauty-shop": "Beauty Shop",
  "boat-tour-agency": "Boat Tour Agency",
  "book-store": "Book Store",
  brewery: "Brewery",
  chiropractor: "Chiropractor",
  "clothing-store": "Clothing Store",
  "coffee-shop": "Coffee Shop",
  "coworking-space": "Coworking Space",
  "dance-studio": "Dance Studio",
  dentist: "Dentist",
  distillery: "Distillery",
  entertainment: "Entertainment",
  entrepreneur: "Entrepreneur",
  farm: "Farm",
  "farmers-market": "Farmers Market",
  florist: "Florist",
  "food-drink-shop": "Food & Drink Shop",
  "general-store": "General Store",
  gym: "Gym",
  "hair-salon": "Hair Salon",
  "home-services": "Home Services",
  hotel: "Hotel",
  "ice-cream-shop": "Ice Cream Shop",
  "personal-trainer": "Personal Trainer",
  "pet-shop": "Pet Shop",
  restaurant: "Restaurant",
  "smoke-shop": "Smoke Shop",
  theater: "Theater",
  "wellness-center": "Wellness Center",
  winery: "Winery",
};

export const FilterContext = createContext<{
  filterState: FilterState;
  filterDispatch: React.Dispatch<FilterAction>;
  searchParams: SearchParams;
  opportunities: OpportunityPreview[];
  setOpportunities: React.Dispatch<React.SetStateAction<OpportunityPreview[]>>;

}>({
  // Filters
  filterState: initialFilterState,
  filterDispatch: () => null,
  searchParams: initialSearchParams,
  opportunities: [],
  setOpportunities: () => 0,
});

export const useFilterContext = () => useContext(FilterContext);

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [filterState, filterDispatch] = useReducer(filterReducer, initialFilterState);
  const [opportunities, setOpportunities] = useState<OpportunityPreview[]>([]);
  const { category, type, location, minprice, maxprice, content } = filterState;

  const searchParams = useMemo(() => {
    const params: SearchParams = {
      ...initialFilterState,
      location,
      minprice,
      maxprice,
      content,
      type: "",
    };

    type.length === 1 ? (params["type"] = type[0] as string) : (params["type"] = "");
    params["category"] = params["type"] === "event" ? "" : (categoryMap[category] as string);

    return params;
  }, [category, type, location, minprice, maxprice, content]);

  return (
    <FilterContext.Provider
      value={{
        filterState,
        filterDispatch,
        searchParams,
        opportunities,
        setOpportunities,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
