import React, { useEffect, useState, useContext } from "react";
import FilterPopup from "./FilterPopup";
import { MdSearch } from "react-icons/md";
import useClickOutside from "src/hooks/useClickOutside";
import PlaceAutoComplete from "src/components/locations/PlaceAutoComplete";
import { useLoadScript } from "@react-google-maps/api";
import { env } from "src/env/client.mjs";
import { useFilterContext } from "../../contexts/FilterContext/FilterContext";
import { AccountService, LocationService } from "src/services";
import { STT_OK } from "src/const/app.const";
import { useAccountContext } from "src/components/contexts/ToggleAccountContext";
import { CloseIcon } from "src/components/common/icons";
import toast from 'react-hot-toast';
import Image from "next/image";

const libraries = ["places"] as "places"[];

const DealLocationFilter = () => {
  const { filterDispatch } = useFilterContext();
  const { accountInfo, isLogged } = useAccountContext();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
    address: "",
  });
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");

  const handleToggleFilter = () => setIsFilterOpen((prev) => !prev);
  const handleCloseFilter = () => setIsFilterOpen(false);

  const handleAutoComplete = async (position: { lat: number; lng: number; address: string }) => {
    const data = await LocationService.getZipcodeFromGeoCoords(position.lat, position.lng);

    if (!data || !data.results[0]) {
      toast.error("Sorry, we could not find the zip code of this location.");
      handleCloseFilter();
      return;
    }

    const addressComponents = data.results[0].address_components;
    const cityComponent = addressComponents.find((component) =>
      component.types.includes("locality")
    );

    const city = cityComponent ? cityComponent.long_name : '';
    setCity(city);

    const zipcodes = data.results
      .map((location) => {
        const zipcode = location.address_components?.find((item) =>
          item.types.includes("postal_code"),
        );
        return zipcode?.long_name;
      })
      .filter((zipcode) => !!zipcode);

    const newZipcode = zipcodes.shift();

    if (!newZipcode) {
      toast.error("Sorry, we could not find the zip code of this location.");
      handleCloseFilter();
      return;
    }

    // Save the newZipcode and city to localStorage
    localStorage.setItem("searchedZipcode", newZipcode);
    localStorage.setItem("searchedCity", city);

    setZipcode(newZipcode);
    setLocation({ ...position });
    handleCloseFilter();
    filterDispatch({
      type: "SET_LOCATION",
      payload: position,
    });
  };

  const getButtonTitle = () => {
    if (zipcode && city && country) {
      return `${city} - ${zipcode}`;
    }
    if (zipcode && city) {
      return `${city} - ${zipcode}`;
    }
    if (zipcode && country) {
      return `${zipcode}, ${country}`;
    }
    if (zipcode) {
      return zipcode;
    }
    if (location.address) {
      return location.address.length > 10
        ? `${location.address.substring(0, 10)}\u2026`
        : location.address;
    }
    return "Anywhere";
  };

  const buttonTitle = getButtonTitle();

  useEffect(() => {
    const fetchZipcodeFromLocalStorage = async () => {
  
      // Retrieve the saved zipcode and city from localStorage
      const savedZipcode = localStorage.getItem("searchedZipcode");
      const savedCity = localStorage.getItem("searchedCity");
  
      if (savedZipcode) {
        setZipcode(savedZipcode);
        setCity(savedCity);
  
        const coordinatesData = await LocationService.getGeoCoordsFromZipcode(savedZipcode);
        const coordinates = coordinatesData?.results[0]?.geometry?.location;
        const address = coordinatesData?.results[0]?.formatted_address;
        const position = { ...coordinates, address };
        setLocation(position);
        filterDispatch({
          type: "SET_LOCATION",
          payload: position,
        });
      } else {
        // Fallback to AccountService if no localStorage data
        const response: any = await AccountService.getInfoCommunity();
        if (response.status === STT_OK && response.data?.community) {
          const { country, community_zipCode } = response.data.community;
          setZipcode(community_zipCode);
          setCountry(country);

          // Fetch actual coordinates asynchronously using the country
          const coordinatesData = await LocationService.getGeoCoordsFromZipcode(community_zipCode, country);
          const coordinates = coordinatesData?.results[0]?.geometry?.location;
          const address = coordinatesData?.results[0]?.formatted_address;

          if (coordinates) {
            const position = { ...coordinates, address };
            setLocation(position);

            filterDispatch({
              type: "SET_LOCATION",
              payload: position,
            });
          }
        }
      }
    };

    if (!accountInfo) {
      return;
    }

    fetchZipcodeFromLocalStorage();
  }, [isLogged]);

  const { ref, exceptionRef } = useClickOutside(handleCloseFilter);

  return (
    <div className="h-11 max-w-[160px] flex-1 text-sm sm:text-base md:relative">
      <div
        className="relative flex h-full w-full cursor-pointer items-center justify-center"
        onClick={handleToggleFilter}
        ref={exceptionRef}
      >
        <Image
          src="/location_icon.svg"
          alt="filter"
          width={30}
          height={30}
        />
      </div>
      {isFilterOpen && isLoaded && !loadError && (
        <div ref={ref}>
          <FilterPopup align="right" width="w-[calc(100vw-50px)] ml-6 md:ml-0 md:w-96">
            <div className="flex h-16 flex-col items-center justify-around rounded-2xl bg-white px-4 shadow">
              <div className="flex h-10 w-full items-center justify-between rounded-lg bg-neutral-50 shadow">
                <MdSearch size={24} className="ml-2" />
                <div className="relative h-full w-full">
                  <PlaceAutoComplete
                    setLatLng={handleAutoComplete}
                    placeholder={buttonTitle === "Anywhere" ? ("city, zip code, neighborhood") : (buttonTitle)}
                    embedded
                  />
                  {zipcode && (
                    <div className="absolute top-[12px] right-1 flex h-5 w-5  items-center justify-center">
                      <CloseIcon
                        size={10}
                        color="#888888"
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation();

                            localStorage.removeItem("searchedZipcode");
                            localStorage.removeItem("searchedCity");
                            setCity("");
                          filterDispatch({
                            type: "SET_LOCATION",
                            payload: null,
                          });
                          setLocation({ address: "", lat: 0, lng: 0 });
                          setZipcode("");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </FilterPopup>
        </div>
      )}
    </div>
  );
};

export default DealLocationFilter;

