import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Message } from "src/types/models";

type MessageReducer = {
  unreadMessages: number;
  lastMessage: Message | undefined;
};
const initialState: MessageReducer = {
  unreadMessages: 0,
  lastMessage: undefined,
};

const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    updateMailBox: (
      state,
      action: PayloadAction<{ message?: Message; amount?: number }>
    ) => {
      if (action.payload.amount !== undefined) {
        state.unreadMessages = action.payload.amount;
      }
      if (action.payload.message) {
        state.lastMessage = action.payload.message;
      }
    },
    clearMailBox: (state) => {
      state.unreadMessages = 0;
      state.lastMessage = undefined;
    },
  },
});

export const { updateMailBox, clearMailBox } = messageSlice.actions;
export default messageSlice.reducer;
