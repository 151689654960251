import {
  STT_OK,
  STT_CREATED,
} from "src/const/app.const";
import { createApi } from ".";
import toast from "react-hot-toast";
import { Comment } from "src/types/models";


interface ICreateCommentBusiness {
  targetId: string;
  targetType: "feed";
  text: string;
  replyTo?: string;
  tagList: object;
}

interface ICreateComment {
  targetId: string;
  targetType: "feed";
  text: string;
  replyTo?: string
  tagList: object;
  businessId?: string;
}

export const AddComment = async (data: ICreateComment) => {
  const res = await createApi().post<{data: Comment, message? :string}>("/comment", data);
  if (res.status !== STT_CREATED || !res.data) {
    toast.error(res.data?.message || "Not a member of the community");
    return null;
  }
  return res.data.data;
};

export const AddCommentBusiness = async (data: ICreateCommentBusiness) => {

  const res = await createApi().post<{data: Comment, message? :string}>("/comment/business", data);
  if (res.status !== STT_CREATED || !res.data) {
    toast.error(res.data?.message || "Not a member of the community");
    return null;
  }  
  return res.data.data;
};


export const GetCommentsList = async (data: {
  targetType: string,
  targetId: string,
  businessId?: string,
  replyTo?: string,
  limit?: number,
  offset?: number
  replyLimit?: number;
  replyOffset?: number;
}) => {
  const res = await createApi().get("/comment",
    data);
  if (res.status === STT_OK) {
    return res.data as { results: Comment[], total: number };
  } else {
    // toast.error("Sorry, an error occured while fetching the comments.");
    return null;
  }
};

interface IDeleteComment {
  id: string;
}
export const DeleteComment = async (data: IDeleteComment) => {
  const res = await createApi().delete<{ message: string; ok: true }>("/comment", {}, { data });
  if (res.status === STT_OK) {
    return res.data;
  } else {
    toast.error("Sorry, an error occured while deleting the comment.");
    return null;
  }
};
