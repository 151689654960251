import {
  STT_WRONG_PARAMS,
  STT_DUPLICATED,
  STT_FORBIDDEN,
  STT_OK,
  STT_TO_MANY_REQUESTS,
} from "src/const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';
import { KeyAbleProps } from "src/types/app";
import { User } from "src/types/models";

interface ILogin {
  email: string;
  password: string;
}

interface ISignUp {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  referralCode: string;
}

interface IUpdateUser {
  name?: string;
  username?: string;
  number?: string;
  password?: string;
  id?: string;
  image?: string;
  bio?: string;
  link?: string;
}

export const login = async (data: ILogin) => {
  const res = await createApi().post<any>("/auth/login", data);

  if (res.status === STT_OK && res.data.accessToken) {
    return res.data;
  } else {
    toast.error("The email or the password is invalid. Please check again.");
    return null;
  }
};

export const refreshAuth = async () => {
  const res = await createApi().post<{ accessToken: string; expiration: string }>("/auth/refresh");

  if (res.status === STT_OK && res.data?.accessToken) {
    return res.data;
  } else {
    toast.error("The email or the password is invalid. Please check again.");
    return null;
  }
};

export const signUp = async (data: ISignUp) => {
  const res = await createApi().post<any>("/register", data);
  if (res.status === STT_OK && res.data.accessToken) {
    return res.data;
  }

  toast.error(res.data.message || "Sorry, something went wrong. Please try again.");
  return false;
};

export const emailAvailable = async (email: string) => {
  // If register returns duplicated means the email already exists
  const res = await createApi().get("/register/email", { email });

  if (res.status === STT_DUPLICATED) {
    toast.error("Sorry, this email is already in use.");
    return false;
  }

  if (res.status === STT_FORBIDDEN) {
    toast.error(res.data.message);
    return false;
  }

  if (res.status === STT_OK) {
    return true;
  }

  // toast.error("Server error: " + res.status || "Internal error");
  return false;
};
export const updateInfo = async (data: IUpdateUser) => {
  const updatedData = await createApi().post("/users/update", data);

  return updatedData as unknown as Record<string, unknown>;
};

// TODO replace for full type
export const getInfo = () =>
  createApi().get<{ community: { community_zipCode: string } }>("/users/info");

export const getInfoBusiness = () =>
  createApi().get<{ community: { community_zipCode: string } }>("/users/business/info")

export const getInfoProfile = () =>
  createApi().get<{ community: { community_zipCode: string } }>("/users/profile/info")

export const getInfoWallet = () =>
  createApi().get<{ community: { community_zipCode: string } }>("/users/wallet/info")

export const getInfoCommunity = () =>
  createApi().get<{ community: { community_zipCode: string } }>("/users/community/info")

export const searchUser = async (keyword: string) => {
  const res = await createApi().get<{ results: User[]; message: string }>(
    `/users/search?keyword=${keyword}&limit=5`,
  );
  if (res.status !== STT_OK || !res.data) {
    toast.error(`${res.data?.message || res.originalError}`);
    return [] as User[];
  }
  return res.data.results;
};

export const searchAllUsers = async (keyword: string,data) => {
  const res = await createApi().get<{ results: User[]; message: string }>(
    `/search/all?keyword=${keyword}&limit=5`,data,
  );
  if (res.status !== STT_OK || !res.data) {
    // toast.error(`${res.data?.message || res.originalError}`);
    return [] as User[];
  }
  return res.data.results;
};


export const getBadgeInfo = async (data :{
  userId: string
}) => {
  const res = await createApi().get<{ result: User; message: string }>(
    `/users/public/info`, data
  );
  if (res.status !== STT_OK || !res.data) {
    return false;
  }
  return res.data.result;
};

export const userBadgeInfo = async (userId: string, businessId?: string | undefined) => {
  const res = await createApi().get(`/users/public/info?userId=${userId}${businessId ? `&businessId=${businessId}` : ""}`);
  return res.data.result; 
}

export const getSimpleInfo = async (data :{
  userId: string
}) => {
  const res = await createApi().get<{ result: User; message: string }>(
    `/users/simple/info`, data
  );
  if (res.status !== STT_OK || !res.data) {
    return false;
  }
  return res.data.result;
};


export const userReferral = async (code: string) => {
  const res = await createApi().get<{ result: User; message: string }>(
    `/users/referral?code=${code}`,
  );
  if (res.status !== STT_OK || !res.data) {
    return false;
  }
  return res.data.result;
};

export const updateImage = (data: any) => createApi().post("/upload-image", data);

export const forgotPassword = async ({
  email,
  returnUrl,
}: {
  email: string;
  returnUrl: string;
}) => {
  const res = await createApi().post<{ message?: string; ok?: true }>("/auth/forgot", {
    email,
    returnUrl,
  });
  if (res?.status === STT_TO_MANY_REQUESTS) {
    toast.error("Please wait one minute before requesting a new email.");
    return undefined;
  }

  if (res?.status !== STT_OK) {
    // toast.error(res.data?.message || "Internal error");
    return undefined;
  }

  return res.data?.ok;
};

export const updatePassword = async ({
  code,
  email,
  newPassword,
}: {
  code: string;
  email: string;
  newPassword: string;
}) => {
  const res = await createApi().post<{ message?: string; ok?: true }>("/auth/reset", {
    code,
    email,
    newPassword,
  });

  if (res?.status === STT_FORBIDDEN) {
    toast.error("Reset password code is expired or invalid. Please request a new code.");
    return undefined;
  }

  if (res?.status !== STT_OK) {
    // toast.error(res.data?.message || "Internal error");
    return undefined;
  }

  return res.data?.ok;
};

export const updateSettingsPassword = async ({
  newPassword,
  oldPassword,
}: {
  newPassword: string;
  oldPassword: string;
}) => {
  const res = await createApi().post<{ message?: string; ok?: true }>("/auth/update", {
    newPassword,
    oldPassword,
  });

  if (res?.status === STT_WRONG_PARAMS) {
    toast.error(res.data?.message || "Wrong parameters");
    return undefined;
  }

  if (res?.status !== STT_OK) {
    // toast.error(res.data?.message || "Internal error");
    return undefined;
  }

  return res.data?.ok;
};

export const updateUser = async (data: KeyAbleProps) => {
  const res = await createApi().post("/update", data);
  if (res?.status !== STT_OK) {
    return res.data;
  }
  return null;
};
