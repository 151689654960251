import React from "react";
import NewModal from "../common/Modal-Redesign";
import Image from "next/image";
import ConfettiExplosion from "react-confetti-explosion";
import { twMerge } from "tailwind-merge";

interface WednesdayWinsModalProps {
  open: boolean;
  onClose: () => void;
  isExploding: boolean;
  setIsExploding: (isExploding: boolean) => void;
  status: string;
  isWinner: boolean;
  isLive: boolean;
}

const WednesdayWinsModal: React.FC<WednesdayWinsModalProps> = ({
  open,
  onClose,
  isExploding,
  setIsExploding,
  status,
  isWinner,
  isLive,
}) => {
  return (
    <NewModal
      open={open}
      onClose={onClose}
      title="Wellness Giveaway"
      subTitle="Relax & Rejuvenate"
      closeButton={true}
      className="md:max-w-[600px] lg:w-[65%]"
    >
      {isExploding && (
        <ConfettiExplosion
          className="z-70"
          zIndex={70}
          particleCount={200}
          colors={["#C19311", "#EBCB73", "#EAAC00"]}
          duration={5000}
          onComplete={() => setIsExploding(false)}
        />
      )}
      <Image
        src="/NewLogo.svg"
        alt="MindKind Logo"
        width={100}
        height={100}
        className={twMerge("mt-4", isLive ? "animate-bounce pt-10" : "py-5")}
      />
      {isWinner && (
        <h1 className="pt-4 text-lg">
          Congratulations! You have won this week's Wellness Giveaway! Please check your
          opportunities to discover your reward.
        </h1>
      )}
      <h1 className="text-md pt-4">{status}</h1>
    </NewModal>
  );
};

export default WednesdayWinsModal;
