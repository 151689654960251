import React, { useContext } from "react";
import { useRouter } from "next/router";
import { deleteBusiness } from "src/services/business.service";
import toast from 'react-hot-toast';
import {
  AccountContext,
  AccountContextType,
} from "../contexts/ToggleAccountContext";
import NewModal from "../common/Modal-Redesign";
interface DeleteBusinessProps {
  onClose: () => void;
  open: boolean;
  businessId: string;
}

const DeleteBusiness: React.FC<DeleteBusinessProps> = ({
  onClose,
  open,
  businessId,
}) => {
  const router = useRouter();
  const { selectBusiness } = useContext<AccountContextType>(AccountContext);
  async function handleBusinessDelete() {
    const business = await deleteBusiness(businessId);
    if (!business) {
      return;
    }
    toast.success("Your business has been deleted. We are sad to see you go.");
    selectBusiness(false);
    router.replace("/account");
  }

  return (
    <>
      <NewModal open={open} onClose={onClose} title="Delete" closeButton={true}>
        <div className="relative flex-auto px-3 py-3 md:px-8">
          <div className="mt-5 text-center text-base md:text-lg">
            Are you sure you want to delete your business account?
          </div>
          <div className="input-subheading-text mt-3 text-center text-base">
            You can only delete your business account if there are no ongoing
            opportunities.
          </div>

          <div className="mt-5 mb-3 flex justify-center">
            <button
              onClick={handleBusinessDelete}
              className="rounded-md bg-black py-3 px-5 font-semibold text-white  lg:w-[30%]"
            >
              Delete{" "}
            </button>
          </div>
        </div>
      </NewModal>
    </>
  );
};

export default DeleteBusiness;
