import { AccountInfoType } from "src/components/contexts/ToggleAccountContext";

export const BUSINESS_ROLES = {
  OWNER: "owner",
  PARTNER: "partner",
  LOCATION_MANAGER: "manager",
  ASSOCIATE: "associate",
};
export const PERMISSION_POLICY = {
  EDIT: "edit",
  READ: "read",
  NONE: "none",
};

export const USER_ACTIONS = {
  ALL: "all", // See all
  WALLET: "wallet", // See the wallet
  OPPORTUNITY: "create", // Create Opportunities
  SETTINGS: "settings", // See the info
  LOCATION: "location", //location
  EDIT_LOCATION: "editlocation", //location
  DELETE_LOCATION: "location", //location
  CONNECT_STRIPE: "stripe", // Connect to Stripe
};

export const USER_PERMISSIONS = {
  [BUSINESS_ROLES.OWNER]: {
    [USER_ACTIONS.ALL]: PERMISSION_POLICY.EDIT,
  },
  [BUSINESS_ROLES.PARTNER]: {
    [USER_ACTIONS.OPPORTUNITY]: PERMISSION_POLICY.EDIT,
    [USER_ACTIONS.SETTINGS]: PERMISSION_POLICY.EDIT,
    [USER_ACTIONS.LOCATION]: PERMISSION_POLICY.EDIT,
    [USER_ACTIONS.WALLET]: PERMISSION_POLICY.READ,
    [USER_ACTIONS.EDIT_LOCATION]: PERMISSION_POLICY.EDIT,
    [USER_ACTIONS.DELETE_LOCATION]: PERMISSION_POLICY.EDIT,
    [USER_ACTIONS.CONNECT_STRIPE]: PERMISSION_POLICY.EDIT,
  },
  [BUSINESS_ROLES.LOCATION_MANAGER]: {
    [USER_ACTIONS.OPPORTUNITY]: PERMISSION_POLICY.EDIT,
    [USER_ACTIONS.SETTINGS]: PERMISSION_POLICY.READ,
    [USER_ACTIONS.LOCATION]: PERMISSION_POLICY.READ,
    [USER_ACTIONS.EDIT_LOCATION]: PERMISSION_POLICY.EDIT,
  },
  [BUSINESS_ROLES.ASSOCIATE]: {
    [USER_ACTIONS.EDIT_LOCATION]: PERMISSION_POLICY.READ,
    [USER_ACTIONS.OPPORTUNITY]: PERMISSION_POLICY.READ,
  },
};

export const getPermission = (
  accountInfo: AccountInfoType | false,
  action: string,
  permission: string,
  { locationId }: { locationId?: string } = {}
) => {
  if (!accountInfo) {
    return false;
  }
  const roles = accountInfo.roles;
  //   if roles is an empty array it means the user is the owner
  if (!roles || roles?.length === 0) {
    return true;
  }

  if (roles?.some((role: any) => role.role === "partner")) {
    return USER_PERMISSIONS[BUSINESS_ROLES.PARTNER]?.[action] === permission;
  }

  // If location is set, then we now which specific role to find
  if (locationId) {
    const role = roles?.find((role: any) => role.locationId === locationId);
    return role && USER_PERMISSIONS[role.role]?.[action] === permission;
  }

  // Other wise we just check by highest role priority
  if (roles?.some((role: any) => role.role === "manager")) {
    return USER_PERMISSIONS[BUSINESS_ROLES.LOCATION_MANAGER]?.[action] === permission;
  }
  if (roles?.some((role: any) => role.role === "associate")) {
    return USER_PERMISSIONS[BUSINESS_ROLES.ASSOCIATE]?.[action] === permission;
  }
};
