import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

interface CommonInputProps {
  value?: string;
  label: string;
  disabled?: boolean;
  name?: string;
  className?: string;
  onChangeValue?: (newValue: string) => void;
  password?: boolean;
  type?: string;
}

const CommonInput: React.FC<CommonInputProps> = ({
  value,
  label,
  disabled,
  name,
  className,
  password,
  type,
  onChangeValue,
}) => {
  const [show, setShow] = useState(!password);

  return (
    <label
      htmlFor={"input" + label}
      className={twMerge(
        `${
          disabled ? "bg-[#D9D9D9]" : "bg-[#FBFBFB]"
        } pt[7px] relative mt-[16px] flex h-[66px] w-full flex-col items-center justify-end overflow-hidden rounded-[5px] border border-gray-200 px-[9px] pb-[4px] ${
          disabled ? "" : "shadow-[0_3px_10px_rgba(0,0,0,0.2)]"
        } ${className ?? ""}`
      )}
    >
      <input
        name={name}
        disabled={disabled}
        value={value}
        type={password && !show ? "password" : type || "text"}
        placeholder={label}
        id={"input" + label}
        onChange={(e) => {
          onChangeValue && onChangeValue?.(e.target.value);
        }}
        className="peer h-8 w-full self-end border-none bg-transparent p-0 text-[20px] placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 focus-visible:outline-none"
      />
      <span className="absolute left-[9px] top-4 -translate-y-1/2 text-[20px] text-[#9B9A9A] transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-[24px] peer-focus:top-4 peer-focus:text-[20px]">
        {label}
      </span>
      {password && (
        <div
          onClick={() => {
            setShow(!show);
          }}
          className="absolute right-3 top-1/2 -translate-y-[10px] cursor-pointer active:opacity-60"
        >
          <svg
            width="28"
            height="20"
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 0.625C7.75 0.625 2.4125 4.5125 0.25 10C2.4125 15.4875 7.75 19.375 14 19.375C20.25 19.375 25.5875 15.4875 27.75 10C25.5875 4.5125 20.25 0.625 14 0.625ZM14 16.25C10.55 16.25 7.75 13.45 7.75 10C7.75 6.55 10.55 3.75 14 3.75C17.45 3.75 20.25 6.55 20.25 10C20.25 13.45 17.45 16.25 14 16.25ZM14 6.25C11.925 6.25 10.25 7.925 10.25 10C10.25 12.075 11.925 13.75 14 13.75C16.075 13.75 17.75 12.075 17.75 10C17.75 7.925 16.075 6.25 14 6.25Z"
              fill="black"
            />
          </svg>
        </div>
      )}
    </label>
  );
};

export default CommonInput;
