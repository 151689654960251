export type FilterState = {
  category: string;
  type: string[];
  location: {
    lat: number;
    lng: number;
    address: string;
  };
  minprice: number;
  maxprice: number;
  content: string;
};
export const initialFilterState: FilterState = {
  category: "all",
  type: ["deal", "event"],
  location: {
    lat: 0,
    lng: 0,
    address: "",
  },
  content: "",
  minprice: 0,
  maxprice: 999,
};

export type FilterAction =
  | { type: "SET_CATEGORY"; payload: string }
  | { type: "SET_TYPE"; payload: string[] }
  | {
      type: "SET_LOCATION";
      payload: { lat: number; lng: number; address: string } | null;
    }
  | { type: "SET_MIN_PRICE"; payload: number }
  | { type: "SET_MAX_PRICE"; payload: number }
  | { type: "SET_CONTENT"; payload: string }
  | { type: "RESET" };

const filterReducer = (state: FilterState, action: FilterAction) => {
  switch (action.type) {
    case "SET_CATEGORY":
      return { ...state, category: action.payload };
    case "SET_TYPE":
      return { ...state, type: action.payload };
    case "SET_LOCATION":
      return { ...state, location: action.payload || { ...initialFilterState.location } };
    case "SET_MIN_PRICE":
      return { ...state, minprice: action.payload };
    case "SET_MAX_PRICE":
      return { ...state, maxprice: action.payload };
    case "SET_CONTENT":
      return { ...state, content: action.payload };
    case "RESET":
      return initialFilterState;
    default:
      return state;
  }
};

export default filterReducer;
