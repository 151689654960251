import { type AppType } from "next/app";
import { ToastContainer } from "react-toastify";
import { Toaster } from 'react-hot-toast';
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { AccountProvider } from "src/components/contexts/ToggleAccountContext";
import "../styles/globals.css";
import "../styles/global.scss";
import setupAxiosInterceptors from "src/config/axios-interceptor";
import store from "../store";
import { OpportunityProvider } from "src/components/contexts/opportunityContext";
import LiveMessages from "src/components/messages/LiveMessages";
import { PageContainer } from "src/components/containers/PageContainer";
import { PageProvider } from "src/components/contexts/PageContext";
import { FilterProvider } from "src/components/contexts/FilterContext/FilterContext";
setupAxiosInterceptors();

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  const requiresPageContainer = (Component as any).requiresPageContainer;
  return (

    <Provider store={store}>
      <AccountProvider>

        <OpportunityProvider>
          <PageProvider>
            <FilterProvider>
            {!requiresPageContainer ? (
            <PageContainer>
              <Component {...pageProps} />
            </PageContainer>
            ) : (
              <Component {...pageProps} />
            )}
            </FilterProvider>
          </PageProvider>

          <ToastContainer
            className="z-60"
            position="top-right"
            autoClose={3500}
            hideProgressBar={false}
            newestOnTop={false}
            draggable={false}
            closeOnClick
            pauseOnHover
          />
          <Toaster />

          <LiveMessages />
        </OpportunityProvider>
      </AccountProvider>
    </Provider>
  );
};

export default MyApp;
