import { STT_OK, STT_UNAUTHORIZED } from "src/const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';
import { Reviews } from "src/types/models";

interface GetReviewsByOpportunityId {
  opportunityId: string;
  offset?: number;
  limit?: number;
}

interface GetReviewsByBusinessId {
  businessId: string;
  offset?: number;
  limit?: number;
}

interface GetReviewsResponse {
  message: string;
  result: Reviews[];
  total: [{ average_rating: number; total_count: number }];
}

const getReviews = async (
  endpoint: string,
  data: GetReviewsByOpportunityId | GetReviewsByBusinessId,
) => {
  const response = await createApi().get<GetReviewsResponse>(endpoint, data);
  const emptyResult = { results: [], average_rating: 0, total_count: 0 };
  // Expire token scenario. The page will be reloaded. Don't show the error
  if (response.status === STT_UNAUTHORIZED) {
    return emptyResult;
  }
  if (response.status !== STT_OK || !response.data) {
    toast.error(`${response.data?.message || response.originalError}`);
    return emptyResult;
  }

  return {
    results: response.data.result,
    average_rating: response.data.total[0]?.average_rating || 0, // Could be NULL if 0, fix in backend too
    total_count: response.data.total[0]?.total_count || 0,
  };
};

export const getReviewsByOpportunityId = async (data: GetReviewsByOpportunityId) => {
  return getReviews("/reviews/opportunityId", data);
};

export const getReviewsByBusinessId = async (data: GetReviewsByBusinessId) => {
  return getReviews("/reviews/businessId", data);
};
