import { OPPORTUNITIES_TYPES, STT_DUPLICATED, STT_OK } from "src/const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';
import { Order } from "src/types/models";

export const getOpportunityCount = async (query: {
  opportunityId: string;
  userId?: string;
  slotId?: string;
}): Promise<{ results: Order[]; total: number }> => {
  const res = await createApi().get<{
    results: Order[];
    total: number;
    message?: string;
  }>("/opportunity-count", query);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`${res.data?.message || res.originalError}`);
    return { results: [], total: 0 };
  }
  return { results: res.data.results, total: res.data.total };
};

export const getUserOrders = async (query: {
  id: string;
  redeem?: string;
  expired?:string;
  limit?: number;
  offset?: number;
}): Promise<{ results: Order[]; total: number }> => {
  const res = await createApi().get<{
    results: Order[];
    total: number;
    message?: string;
  }>("/user-orders", query);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`${res.data?.message || res.originalError}`);
    return { results: [], total: 0 };
  }
  return { results: res.data.results, total: res.data.total };
};


export const getValidateOrders = async (query: {
  user_id?: string;
  businessId?: string;
  opportunityId?: string;
  redeemCode?: string;
  redeem?: string;
  limit?: number;
  offset?: number;
  purchaseCount?: boolean;
  orderBy?: 'createdAt' | 'expiresAt'; //can be added later
  orderDirection?: 'ASC' | 'DESC'
}): Promise<{ results: Order[]; total: number }> => {
  const res = await createApi().get<{
    results: Order[];
    total: number;
    message?: string;
  }>("/validate-orders", query);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`${res.data?.message || res.originalError}`);
    return { results: [], total: 0 };
  }
  return { results: res.data.results, total: res.data.total };
};

export const getOrderById = async (data: { id: string }) => {
  const res = await createApi().get<{ result: Order; message?: string }>(
    "/orders/info",
    data
  );
  if (res.status !== STT_OK || !res.data) {
    toast.error(`${res.data?.message || res.originalError}`);
    return false;
  }
  return res.data.result;
};

export const getOrdersToValidate = async (body: {
  businessId: string;
  userName: string;
  limit?: number;
  offset?: number;
}): Promise<Order[]> => {
  const res = await createApi().get<{ results: Order[]; message?: string }>(
    "/orders/business/validate",
    body
  );
  if (res.status !== STT_OK || !res.data) {
    return [];
  }
  return res.data.results;
};

export const keepRedeemAmountInRedis = async (data: {
  orderId: string;
  amountToSpend?: number;
}): Promise<string> => {
  const res = await createApi().post<{ redeemCode: string; amount?: string }>(
    "/orders/business/validate/value",
    data
  );
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.originalError}`);
    return "";
  }
  return res.data.redeemCode;
};

export const checkRedeemCode = async (data: {
  opportunityId: string;
  userId: string;
  redeemCode: string;
}): Promise<Order | false> => {
  const res = await createApi().post<{
    ok: boolean;
    message?: string;
    order: Order;
  }>("/orders/business/validate/checkredeemcode", data);

  if (res.status !== 200) {
    return false;
  }

  return res.data?.order || false;
};

export const getRedeemAmountInRedis = async (order: {
  opportunityId: string;
  userId: string;
  opportunityType: string;
}): Promise<{ redeemCode: string; amount: string } | false> => {
  const res = await createApi().get<{ redeemCode: string; amount?: string }>(
    "/orders/business/validate/value",
    {
      opportunityId: order.opportunityId,
      userId: order.userId,
      opportunityType: order.opportunityType,
    }
  );
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.originalError}`);
    return false;
  }

  return res.data as { redeemCode: string; amount: string };
};
export const expiredOpportunity = async (order: Order) => {
  // Different types of opportunity follow different expiration policies (endpoint)
  const endpoint = order.opportunityType === OPPORTUNITIES_TYPES.GIFT_CARD ? "giftcard" : order.opportunityType;
  const res = await createApi().post<{ result: Order; message: string }>(
    `/orders/expired/${endpoint}`,
    { opportunityId: order.opportunityId, code: order.redeemCode }
  );
  if (res.status !== STT_OK) {
    toast.error(`${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};

export const giftOrder = async (data: {
  orderId: string;
  message: string;
  receiverId: string;
}) => {
  // Different types of opportunity follow different expiration policies (endpoint)
  const res = await createApi().post<{ result: Order; message: string }>(
    `/orders/gift`,
    data
  );
  if (res.status === STT_DUPLICATED) {
    toast.error(`You cannot gift an opportunity to yourself.`);
    return false;
  }
  if (res.status !== STT_OK) {
    toast.error(`${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};

export const redeemOpportunity = async (body: {
  opportunityId: string;
  code: string;
  locationId: string;
  userId: string;
}): Promise<Order | false> => {
  const res = await createApi().post<{
    ok: string;
    order: Order;
    message: string;
  }>("/orders/redeem", body);
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return res.data.order;
};

export const redeemBalance = async (body: {
  opportunityId: string;
  userId: string;
  code: string;
  locationId: string;
  orderId: string;
}): Promise<Order | false> => {
  const res = await createApi().post<{
    ok: string;
    order: Order;
    message: string;
  }>("/orders/redeem/balance", body);
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return res.data.order;
};

export const keepRedeemAmountInDB = async (data: {
  orderId: string;
  amountToSpend?: number;
}): Promise<string> => {
  const res = await createApi().put<{ redeemCode: string; amount?: string }>(
    "/orders/business/validate/value",
    data
  );
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.originalError}`);
    return "";
  }
  return res.data.redeemCode;
};
