import { createApi } from ".";

interface TypeData {
  id: string;
  name: string;
  type: string;
  image: string;
  isFollowed: string;
}

interface SearchFollowersResponse {
  results: TypeData[];
  completed: number;
  pending: number;
  total: number;
  message?: string;
}

interface FollowListResponse {
  ok: boolean;
  total: number;
  results: {
    id: string;
    name: string;
    image: string;
    type: string;
  }[];
}

interface UnFollowResponse {
  ok: boolean;
}

interface FollowerData {
  id: string;
  name: string;
  type: string;
  image: string;
}

interface FollowersResponse {
  ok: boolean;
  total: number;
  results: FollowerData[];
  message?: string;
}

export const searchFollowers = async (keyword: string = "", limit: number = 10): Promise<TypeData[]> => {
  try {
    const res = await createApi().get<SearchFollowersResponse>('/social/search', {
        keyword, 
        limit,   
    });
    return res.data.results || [];
  } catch (error) {
    console.error('Error searching followers:', error);
    throw new Error("Failed to search followers. Please try again.");
  }
};


export const follow = async (type: string, followedId: string, businessId: string | undefined): Promise<{ ok: boolean }> => {
  try {
    const res = await createApi().post<{ ok: boolean }>(`/social/follow/${type}`, { followedId, businessId });
    return res.data;
  } catch (error) {
    console.error('Error following:', error);
    throw new Error("Failed to follow. Please try again.");
  }
};

export const getMyFollowList = async (
  limit: number,
  offset: number,
  businessId?: string
): Promise<FollowListResponse> => {
  try {
    const res = await createApi().get<FollowListResponse>('/social/my-follow-list', { 
      limit,
      offset,
      ...(businessId && { businessId }), 
    });
    return res.data ;
  } catch (error) {
    console.error('Error fetching follow list:', error);
    throw new Error("Failed to fetch follow list. Please try again.");
  }
};

export const unFollow = async (
  followedId: string,
  businessId?: string
): Promise<UnFollowResponse> => {
  try {
    const payload = {
      followedId,
      ...(businessId && { businessId }),
    };

    const res = await createApi().post<UnFollowResponse>('/social/un-follow', payload);
    return res.data.results;
  } catch (error) {
    console.error('Error unfollowing user:', error);
    throw new Error("Failed to un-follow user. Please try again.");
  }
};


export const getFollowers = async (
  userId: string,
  limit: number ,
  offset: number 
): Promise<FollowerData[]> => {
  try {
    const res = await createApi().get<FollowersResponse>(`/social/followers/${userId}`, {
        limit,
        offset
    });
    
    if (res.data.ok) {
      return res.data;
    } else {
      throw new Error(res.data.message || "Failed to fetch followers");
    }
  } catch (error) {
    console.error('Error fetching followers:', error);
    throw new Error("Failed to fetch followers. Please try again.");
  }
};
